import React, { useState, useEffect } from 'react';
import OfferTable from '../../components/OfferTable';
import { useTranslation } from 'react-i18next';
import AddCategoriesModal from './AddCategoriesModal';
import {
	Cached,
	FirstPage,
	KeyboardArrowLeft,
	KeyboardArrowRight,
	LastPage,
} from '@mui/icons-material';
import { Zoom, IconButton, Typography, Box } from '@mui/material';
import { OfferToolTip } from '../../helpers';
import CategoriesPopover from './CategoriesPopover';
import { usePO } from '../../utils/POContext';
import CategoriesToggleDialog from './CategoriesToggleDialog';
import Unauthorized from '../Page401';
import { fetchAvaliableCategories } from '../../services/categories';
import { enqueueSnackbar } from 'notistack';
import SelectActiveClient from '../../components/SelectClient';
import { CategoryType } from '../../interfaces/Categories/index';
import { Line } from '../../styles';
import EditCategoriesModal from './EditCategoriesModal';
import { closeAllPanels } from '../../redux/slices/expansibleTableSlice';
import { useDispatch } from 'react-redux';

const Categories = () => {
	const { t } = useTranslation();
	const { selectedTheme, userData, selectedClient } = usePO();

	const dispatch = useDispatch();

	const [rowsPerPage, setRowsPerPage] = useState(
		window.innerHeight > 750 ? 10 : Math.round(window.innerHeight / 100) + 1
	);
	const [tableLength, setTableLength] = useState(1);
	const [currentPage, setCurrentPage] = useState(0);
	const [currentPageChange, setCurrentPageChange] = useState(0);
	const [displayCurrentPage, setDisplayCurrentPage] = useState(0);

	const [tableData, setTableData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [openPopover, setOpenPopover] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [popoverData, setPopoverData] = useState({ route: [] });
	const [openToggleDialog, setOpenToggleDialog] = useState(false);
	const [receivedDataSwitch, setReceivedDataSwitch] = useState<any>();

	const handlePopoverOpen = (event, routeData) => {
		setOpenPopover(true);
		setAnchorEl(event.currentTarget);
		setPopoverData({ route: routeData });
	};

	const handlePopoverClose = () => {
		setOpenPopover(!openPopover);
		setAnchorEl(null);
	};

	const handleOpenToggleCategoriesDialog = () => {
		setOpenToggleDialog(true);
	};

	const categoriesFilters = {
		orderField: 'id',
		ordination: 'ASC',
		clientID: selectedClient.id,
		itemsPerPage: rowsPerPage,
		page: currentPage + 1,
	};

	const fetchData = async () => {
		setLoading(true);
		const response = await fetchAvaliableCategories(categoriesFilters);
		if (response?.success === true) {
			buildTableData(response.data.hits);
			setTableLength(response.data.totalHits);
			setDisplayCurrentPage(currentPage);
		} else {
			buildTableData([]);
			enqueueSnackbar(
				t(`Categorias.${response?.message || 'Erro desconhecido'}`),
				{
					variant: 'error',
				}
			);
		}
		setLoading(false);
	};

	const rowsPerPageOptions = [
		window.innerHeight > 750 ? 10 : Math.round(window.innerHeight / 100) + 1,
		50,
		100,
	];

	const handleChangeRowsPerPage = (event) => {
		if (currentPage > 0) {
			setCurrentPage(0);
			setDisplayCurrentPage(0);
			setLoading(true);
			setRowsPerPage(event.target.value);
		}
		if (currentPage === 0) {
			setRowsPerPage(event.target.value);
		}
	};

	const handleChangePage = (event, newPage: React.SetStateAction<number>) => {
		dispatch(closeAllPanels());
		setLoading(true);
		setCurrentPage(newPage);
		setCurrentPageChange(currentPageChange + 1);
	};

	const handleKeyArrowStyle = (disableVerification) => {
		if (disableVerification) {
			return selectedTheme.id === 'dark' ? selectedTheme.textColorDisable : '';
		} else {
			return selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '';
		}
	};

	function tablePaginationActions() {
		const handleFirstPageButtonClick = (
			event: React.MouseEvent<HTMLButtonElement>
		) => {
			handleChangePage(event, 0);
		};

		const handleBackButtonClick = (
			event: React.MouseEvent<HTMLButtonElement>
		) => {
			handleChangePage(event, currentPage - 1);
		};

		const handleNextButtonClick = (
			event: React.MouseEvent<HTMLButtonElement>
		) => {
			handleChangePage(event, currentPage + 1);
		};

		const handleLastPageButtonClick = (
			event: React.MouseEvent<HTMLButtonElement>
		) => {
			handleChangePage(
				event,
				Math.max(0, Math.ceil(tableLength / rowsPerPage) - 1)
			);
		};
		return (
			<Box
				style={{
					flexShrink: 0,
					marginLeft: 30,
				}}
			>
				<Line>
					<IconButton
						onClick={handleFirstPageButtonClick}
						disabled={currentPage === 0}
						aria-label="first page"
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<FirstPage
							style={{ color: handleKeyArrowStyle(currentPage === 0) }}
						/>
					</IconButton>
					<IconButton
						onClick={handleBackButtonClick}
						disabled={currentPage === 0}
						aria-label="previous page"
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<KeyboardArrowLeft
							style={{ color: handleKeyArrowStyle(currentPage === 0) }}
						/>
					</IconButton>
					<IconButton
						onClick={handleNextButtonClick}
						disabled={currentPage >= Math.ceil(tableLength / rowsPerPage) - 1}
						aria-label="next page"
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<KeyboardArrowRight
							style={{
								color: handleKeyArrowStyle(
									currentPage >= Math.ceil(tableLength / rowsPerPage) - 1
								),
							}}
						/>
					</IconButton>
					<IconButton
						onClick={handleLastPageButtonClick}
						disabled={currentPage >= Math.ceil(tableLength / rowsPerPage) - 1}
						aria-label="last page"
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<LastPage
							style={{
								color: handleKeyArrowStyle(
									currentPage >= Math.ceil(tableLength / rowsPerPage) - 1
								),
							}}
						/>
					</IconButton>
				</Line>
			</Box>
		);
	}

	const buildTableData = async (data) => {
		if (data) {
			setTableData([]);
			const tempTableData: any = [];
			await data.map((item) => {
				const hasParent =
					item.parent &&
					item.route?.find((route) => route.id === item.parent)?.name
						? item.route.find((route) => route.id === item.parent).name
						: '-';
				const formattedRoute = item.route
					? item.route.map((route) => route.name).join(' > ')
					: 'N/A';
				const routeCell = (
					<Typography
						style={{
							cursor: 'pointer',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}
						onClick={(event) => handlePopoverOpen(event, item.route)}
					>
						{formattedRoute}
					</Typography>
				);
				const formattedData: CategoryType = {
					id: item.id,
					name: item.name,
					description: item.description,
					parent: hasParent,
					clientID: item.clientID,
					created: item.created,
					formatedRoute: routeCell,
					ISactive: item.ISactive,
					route: item.route,
					custom: item.custom,
				};
				tempTableData.push(formattedData);
			});
			setTableData(tempTableData);
		}
	};

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedClient, currentPageChange, rowsPerPage]);

	const renderFilter = () => (
		<Line>
			<Typography
				style={{
					margin: '0 5px 0 20px',
					color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
				}}
			>
				{t('Buscas.Tabs.Monitoramento.Cliente')}:
			</Typography>
			<SelectActiveClient width="200px" />
		</Line>
	);

	const switchesToolTip = {
		true: t('Categoria ativada'),
		false: t('Categoria desativada'),
	};

	const colsData = [
		{
			columnTitle: t('ID'),
			fieldName: 'id',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 30,
		},
		{
			columnTitle: t('Categorias.Título'),
			fieldName: 'name',
			translateColumnData: true,
			alignTitle: 'center',
			cellWidth: 90,
		},
		{
			columnTitle: t('Categorias.Descrição'),
			fieldName: 'description',
			translateColumnData: true,
			alignTitle: 'center',
			cellWidth: 150,
		},
		{
			columnTitle: t('Categorias.Pai'),
			fieldName: 'parent',
			translateColumnData: true,
			alignTitle: 'center',
			cellWidth: 50,
		},
		{
			columnTitle: t('Categorias.Caminho'),
			fieldName: 'formatedRoute',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 200,
			dataType: 'reactObject',
		},
		{
			columnTitle: t('Categorias.Data'),
			fieldName: 'created',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 90,
			dataType: 'date',
		},
	];

	const action1Props = {
		refresh: fetchData,
	};
	const CustomHeaderComponentProps = {
		refresh: fetchData,
	};

	const renderReloadTableData = () => (
		<OfferToolTip
			title={`${t('Atualizar dados da tabela')}`}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				onClick={fetchData}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<Cached
					sx={{
						animation: loading ? '$spin 2s linear infinite' : 'normal',
						'@keyframes spin': loading
							? {
									'0%': { transform: 'rotate(360deg)' },
									'100%': { transform: 'rotate(0deg)' },
								}
							: null,
						zIndex: !loading ? 2 : 'auto',
						fontSize: '22px',
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);

	return (
		<>
			{userData.roles.includes('Desenvolvedor') ? (
				<>
					<OfferTable
						colsData={colsData}
						tableData={tableData}
						CustomHeaderComponent={AddCategoriesModal}
						CustomHeaderComponentProps={CustomHeaderComponentProps}
						ActionComponent1={EditCategoriesModal}
						ActionComponent1Props={action1Props}
						customCurrentPage={displayCurrentPage}
						customRowsPerPage={rowsPerPage}
						customRowsPerPageOptions={rowsPerPageOptions}
						customTableLength={tableLength}
						customTablePagination={tablePaginationActions}
						handleChangeCustomRowsPerPage={handleChangeRowsPerPage}
						tableFooter
						windowHeader
						tableActions
						windowTitle={t('Categorias.Categorias')}
						customRightFooterComponent={renderFilter()}
						customLeftFooterComponent={renderReloadTableData()}
						customNoDataMessage={
							// eslint-disable-next-line no-nested-ternary
							selectedClient
								? t('Sem categorias para este cliente')
								: t('Selecione um cliente')
						}
						loading={loading}
						switches
						switchesToolTip={switchesToolTip}
						dataFromSwitch={setReceivedDataSwitch}
						onSwitchChange={handleOpenToggleCategoriesDialog}
					/>
					<CategoriesToggleDialog
						open={openToggleDialog}
						setOpen={setOpenToggleDialog}
						data={receivedDataSwitch}
						onConfirm={fetchData}
					/>
					{anchorEl && (
						<CategoriesPopover
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							onClose={handlePopoverClose}
							routeData={popoverData}
						/>
					)}
				</>
			) : (
				<div style={{ marginLeft: '-75px' }}>
					<Unauthorized />
				</div>
			)}
		</>
	);
};

export default Categories;
