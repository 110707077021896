import { ErrorResponse } from '../../interfaces';
import { api, isAxiosError } from '../../utils/api';

export const getDomainsFilters = async (payload) => {
	try {
		const response = await api.post('/domains/filters', payload);
		return { success: true, data: response.data };
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const toggleDomain = async (domainID, state, payload) => {
	try {
		const response = await api.patch(`/domains/${domainID}`, payload);
		return {
			success: true,
			status: response.status,
			data: [],
			message: response.data.detail,
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
					data: [],
				};
			}
		}
	}
};
