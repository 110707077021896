import React, { useState } from 'react';
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	IconButton,
	Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import { usePO } from '../../../utils/POContext';
import { Column, Line } from '../../../styles';
import Panel from '../../../components/Panel';
import { useCardSelector } from '../../../hooks/Classificacoes/CardSelector';

interface Props {
	data: any;
	open: boolean;
	close: () => void;
}

const SubmitedResumeDialog: React.FC<Props> = ({
	data,
	open,
	close,
}: Props) => {
	const { handleSelectCard } = useCardSelector();
	const { selectedTheme } = usePO();
	const { t } = useTranslation();

	const [keepSelected, setKeepSelected] = useState(false);

	const handleSelect = (id) => {
		handleSelectCard({ offerID: id }, 'add');
	};

	const handleClose = () => {
		if (keepSelected) {
			handleSelectCard(null, 'clear');
			data?.notGood.map((el) => handleSelect(el.id));
			close();
		} else {
			handleSelectCard(null, 'clear');
			close();
		}
	};

	const handleChangeCheckbox = (event) => {
		setKeepSelected(event.target.checked);
	};

	const renderNotGoodMessages = () =>
		data?.good.length > 0
			? `${data?.notGood.length} ${t('Classificações.de')} ${
					data?.good.length + data?.notGood.length
				} ${t('Classificações.anúncios não puderam ser classificados')}:`
			: `${data?.notGood.length} ${t(
					'Classificações.anúncios não puderam ser classificados'
				)}:`;

	const renderDialogTitle = () => (
		<Line style={{ justifyContent: 'space-between', alignItems: 'center' }}>
			<Typography
				variant="h6"
				style={{
					color: selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
				}}
			>
				{data?.notGood.length === 0
					? data.good.length + t('Classificações. anúncios foram classificados')
					: renderNotGoodMessages()}
			</Typography>
			<IconButton
				size="small"
				onClick={close}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<Close
					style={{
						color:
							selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
					}}
				/>
			</IconButton>
		</Line>
	);

	const renderNotGoodResults = () => (
		<Column style={{ width: '100%', alignItems: 'flex-start' }}>
			{data?.notGood.length > 0 && (
				<Panel
					title={t('Classificações.Itens falhados')}
					contentPadding="10px 20px"
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
							gap: 15,
						}}
					>
						<Typography
							variant="caption"
							sx={{
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							}}
						>
							{t('Classificações.ID da oferta')}:{' '}
							{data.notGood.map((g) => g.id).join(', ')}
						</Typography>
						<Typography variant="caption" sx={{ color: selectedTheme.error }}>
							{t('Classificações.Motivo')}:
							<br />
							{data.notGood.map((item, index) => (
								<span key={index} style={{ marginBottom: '15px' }}>
									{t(`Classificações.${item.detail}`)}
									<br />
								</span>
							))}
						</Typography>
					</div>
				</Panel>
			)}
		</Column>
	);

	const renderGoodResults = () => (
		<Column style={{ width: '100%', alignItems: 'flex-start' }}>
			{data?.good.length > 0 && (
				<Panel
					title={t('Classificações.Itens atualizados')}
					contentPadding="10px 20px"
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
							gap: 15,
						}}
					>
						<Typography
							variant="caption"
							sx={{
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							}}
						>
							{t('Classificações.ID da oferta')}:{' '}
							{data.good.map((g) => g.id).join(', ')}
						</Typography>
						<Typography variant="caption" sx={{ color: selectedTheme.success }}>
							{t('Classificações.Mensagem')}:
							<br />
							{data.good.map((item, index) => (
								<span key={index} style={{ marginBottom: '15px' }}>
									{t(`Classificações.${item.detail}`)}
									<br />
								</span>
							))}
						</Typography>
					</div>
				</Panel>
			)}
		</Column>
	);

	return (
		<Dialog maxWidth="sm" fullWidth open={open} onClose={close}>
			<DialogTitle
				style={{
					display: 'flex',
					borderBottom: 'solid',
					borderBottomWidth: 1,
					borderBottomColor:
						selectedTheme.id === 'dark' ? selectedTheme.footerLine : '#eaeaea',
					padding: '12px 20px',
					background:
						selectedTheme.id === 'dark'
							? selectedTheme?.tableHead
							: selectedTheme.gradient,
				}}
			>
				{renderDialogTitle()}
			</DialogTitle>
			<DialogContent style={{ padding: 0 }}>
				<Column
					style={{
						alignItems: 'center',
						background:
							selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
						padding: '10px 15px',
					}}
				>
					<Column style={{ width: '100%' }}>
						{renderGoodResults()}
						{renderNotGoodResults()}
					</Column>
				</Column>
			</DialogContent>
			<DialogActions
				style={{
					borderTop: 'solid',
					borderTopWidth: 1,
					borderTopColor:
						selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
					background:
						selectedTheme.id === 'dark'
							? selectedTheme?.overlay3dp
							: selectedTheme.foreground,
				}}
			>
				<Line
					style={{
						justifyContent:
							data?.notGood.length > 0 ? 'space-between' : 'flex-end',
						margin: '2px 10px 0px 10px',
						columnGap: 20,
					}}
				>
					{data?.notGood.length > 0 ? (
						<FormControlLabel
							control={
								<Checkbox
									checked={keepSelected}
									onChange={handleChangeCheckbox}
									size="small"
									style={{
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorHigh
												: selectedTheme.primaryDark,
									}}
									sx={{
										'&:hover': {
											backgroundColor:
												selectedTheme.id === 'dark' &&
												selectedTheme.primaryLight,
										},
									}}
								/>
							}
							label={
								<Typography
									variant="subtitle2"
									style={{
										marginBottom: -2,
										color:
											selectedTheme.tone === 'dark' &&
											selectedTheme.textColorHigh,
									}}
								>
									{t('Classificações.Manter seleção dos anúncios falhados')}
								</Typography>
							}
							style={{ marginTop: -3, marginLeft: -5 }}
						/>
					) : null}

					<Button
						data-testid="closeButton"
						size="small"
						onClick={handleClose}
						style={{
							border: `1px solid ${
								selectedTheme.id === 'dark'
									? selectedTheme.footerLine
									: 'transparent'
							}`,
							color:
								selectedTheme.tone === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.foreground,
							background: selectedTheme.primaryDark,
							width: 'auto',
						}}
					>
						{t('Classificações.Fechar')}
					</Button>
				</Line>
			</DialogActions>
		</Dialog>
	);
};

export default SubmitedResumeDialog;
