import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
	IconButton,
	Zoom,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Delete } from '@mui/icons-material/';
import LoadingDots from '../../../../components/LoadingDots';
import { Line } from '../../../../styles';
import { usePO } from '../../../../utils/POContext';
import { OfferToolTip } from '../../../../helpers';
import { api } from '../../../../utils/api';
import { MessageType } from '../../../../interfaces';
import { enqueueSnackbar } from 'notistack';

interface Props {
	reloadTable?: any;
	rowData?: any;
}

const DeleteDenouncementDialog: React.FC<Props> = ({
	reloadTable,
	rowData,
}: Props) => {
	const { t } = useTranslation();
	const { selectedTheme, cursorMode, selectedClient, userData } = usePO();

	const [loading, setLoading] = React.useState(false);
	const [open, setOpen] = useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const handleSnackbarMessage = (message: string, type: MessageType) => {
		enqueueSnackbar(message, { variant: type });
	};

	/** Checks if user meets conditions to Delete the item */
	const selectDisable = () => {
		if (
			userData.roles?.indexOf('Desenvolvedor') !== -1 ||
			userData.roles?.indexOf('Administrador da ferramenta') !== -1
		) {
			return false;
		}
		if (rowData?.classificationTypeID === 'SUSPT') {
			return false;
		}
		if (rowData?.denouncerEmail === userData.email) {
			return false;
		}
		return true;
	};

	const handleDeleteSearch = async () => {
		try {
			setLoading(true);
			const response = await api.delete(
				`/offerClassification/${rowData.id}/clients/${selectedClient?.id}`
			);
			setLoading(false);
			handleSnackbarMessage(response.data.detail, 'success');
			handleClose();
			setTimeout(() => {
				reloadTable();
			}, 500);
		} catch (error) {
			setLoading(false);
			handleSnackbarMessage(
				t('Erro ao remover a denúncia. Tente novamente mais tarde.'),
				'error'
			);
			handleClose();
		}
	};

	const renderDeleteIcon = () => (
		<>
			<OfferToolTip
				title={`${t('Notificações.Tabs.Classificadas.Remover Denúncia')}`}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
				data-testid="deletIcon"
			>
				<IconButton onClick={() => setOpen(true)} disabled={selectDisable()}>
					<Delete
						style={{
							color: !selectDisable()
								? selectedTheme.error
								: selectedTheme.id === 'dark'
									? '#4F4F4F'
									: 'lightgray',
							fontSize: 24,
						}}
					/>
				</IconButton>
			</OfferToolTip>
		</>
	);

	const renderDialogContent = () => (
		<Line
			style={{
				justifyContent: 'space-between',
				padding: '3px 10px 0 10px',
			}}
			data-testid="modalDeleteDenounce"
		>
			<Typography
				style={{
					color:
						selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
				}}
				variant="h6"
			>
				{t('Notificações.Tabs.Classificadas.Deseja excluir a denúncia')}:
			</Typography>
			<OfferToolTip
				title={`${rowData?.title || ''}`}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<Typography
					noWrap
					variant="h6"
					style={{
						marginLeft: 5,
						maxWidth: 300,
						color: selectedTheme.textColorMedium,
					}}
				>
					{`${rowData?.title || ''}`}
				</Typography>
			</OfferToolTip>
			<Typography
				variant="h6"
				style={{
					color:
						selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
				}}
			>
				?
			</Typography>
		</Line>
	);

	const renderFooter = () => (
		<Line
			style={{
				justifyContent: 'space-between',
			}}
		>
			<Button
				size="small"
				onClick={handleClose}
				disabled={loading}
				style={{
					color: loading ? 'gray' : selectedTheme.error,
				}}
				data-testid="closeModal"
			>
				{t('Notificações.Tabs.Classificadas.Cancelar')}
			</Button>
			{loading ? (
				<LoadingDots height={30} width={70} loop />
			) : (
				<Button
					data-testid="confirmDelete"
					size="small"
					style={{
						color:
							selectedTheme.id === 'dark'
								? selectedTheme.textColorHigh
								: selectedTheme.primaryDark,
						background:
							selectedTheme.id === 'dark' ? selectedTheme.primaryDark : '',
						border: `1px solid ${
							selectedTheme.id === 'dark'
								? selectedTheme.footerLine
								: selectedTheme.foreground
						}`,
					}}
					onClick={handleDeleteSearch}
				>
					{t('Notificações.Tabs.Classificadas.Excluir')}
				</Button>
			)}
		</Line>
	);

	return (
		<div>
			{renderDeleteIcon()}
			<Dialog
				open={open}
				disableEscapeKeyDown
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xl"
				style={{ cursor: cursorMode }}
			>
				<DialogContent
					style={{
						padding: '20px 30px',
						background:
							selectedTheme.id === 'dark' ? selectedTheme.overlay8dp : '',
					}}
				>
					{renderDialogContent()}
				</DialogContent>
				<DialogActions
					style={{
						borderTop: 'solid',
						borderTopWidth: 1,
						borderTopColor:
							selectedTheme.id === 'dark'
								? selectedTheme?.footerLine
								: '#eaeaea',
						padding: '10px 15px',
						background:
							selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
					}}
				>
					{renderFooter()}
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default DeleteDenouncementDialog;
