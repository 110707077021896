import React, { useEffect, useState } from 'react';
import { Fab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MenuOpen } from '@mui/icons-material/';
import {
	CircleMenu,
	CircleMenuItem,
	TooltipPlacement,
} from 'react-circular-menu';
import { useTranslation } from 'react-i18next';
import { usePO } from '../../utils/POContext';
import { OfferToolTip } from '../../helpers';

interface MenuItem {
	icon: any;
	action: any;
	tooltip?: string;
}

interface Props {
	menuData: MenuItem[];
	loading?: boolean;
}

const RadialMenu: React.FC<Props> = ({ menuData, loading }: Props) => {
	const { selectedTheme } = usePO();
	const { t } = useTranslation();

	const useStyles = makeStyles({
		radialMenuMain: {
			position: 'fixed',
			right: 20,
			bottom: 30,
			color:
				selectedTheme.id === 'dark'
					? selectedTheme.primary
					: selectedTheme.primaryDark,
			cursor: 'pointer',
		},
		radialMenuItem: {
			margin: 10,
			background:
				selectedTheme.id === 'dark'
					? selectedTheme.primary
					: selectedTheme.primaryDark,
			color: 'white',
			borderWidth: 0,
			boxShadow: '5px 5px 6px -3px rgba(0,0,0,0.3)',
			'&:hover': {
				backgroundColor:
					selectedTheme.id === 'dark' ? '#565656' : selectedTheme.primaryLight,
			},
			'&:focus': {
				backgroundColor: selectedTheme.primary,
			},
		},
		fab: {
			'&:hover': {
				backgroundColor:
					selectedTheme.id === 'dark' ? '#565656' : selectedTheme.primaryLight,
			},
			'&:focus': {
				backgroundColor: selectedTheme.primary,
			},
			background:
				selectedTheme.id === 'dark'
					? selectedTheme.primary
					: selectedTheme.primaryDark,
			position: 'fixed',
			right: 8,
			bottom: 8,
			zIndex: 1,
		},
	});
	const classes = useStyles();

	const [menuKey, setMenuKey] = useState(0);

	useEffect(() => {
		if (loading) handleCloseMenu();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	const handleCloseMenu = () => {
		setTimeout(() => {
			setMenuKey(menuKey + 1);
		}, 250);
	};

	const buildMenuItems = () => {
		return menuData
			.filter((menuItem) => menuItem && menuItem.action)
			.map((menuItem, i) => {
				const { icon, action, tooltip } = menuItem;

				return (
					<CircleMenuItem
						key={i}
						tooltipPlacement={TooltipPlacement.Left}
						className={classes.radialMenuItem}
						onClick={action}
						tooltip={t(tooltip || '')}
						menuActive={!loading}
					>
						{icon}
					</CircleMenuItem>
				);
			});
	};

	const renderMenu = () => {
		const menuItems = buildMenuItems();

		if (menuItems.length === 1) {
			return (
				<OfferToolTip title={t(menuItems[0].props.tooltip)}>
					<Fab
						className={classes.fab}
						size="small"
						onClick={menuItems[0].props.onClick}
						disabled={loading}
					>
						{menuItems[0].props.children}
					</Fab>
				</OfferToolTip>
			);
		}

		return (
			<CircleMenu
				startAngle={180}
				rotationAngle={120}
				itemSize={1.4}
				radius={3.5}
				key={menuKey}
				menuToggleElement={
					<MenuOpen
						style={{
							fontSize: 44,
							background:
								selectedTheme.id === 'dark'
									? selectedTheme.primary
									: selectedTheme.primaryDark,
							color: 'white',
							borderRadius: '50%',
							borderWidth: 10,
							borderStyle: 'solid',
							borderColor:
								selectedTheme.id === 'dark'
									? selectedTheme.primary
									: selectedTheme.primaryDark,
							boxShadow: '5px 5px 6px -3px rgba(0,0,0,0.3)',
						}}
					/>
				}
				rotationAngleInclusive={false}
				className={classes.radialMenuMain}
			>
				{menuItems}
			</CircleMenu>
		);
	};

	return (
		<>
			<div onBlur={handleCloseMenu}>{renderMenu()}</div>
		</>
	);
};

export default RadialMenu;
