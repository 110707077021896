import React, { useState, useEffect } from 'react';
import { api, setupRequestToken } from '../../../utils/api';
import { MultiDropdownList, ReactiveList } from '@appbaseio/reactivesearch';
import { useAuth0 } from '../../../react-auth0-spa';
import { usePO } from '../../../utils/POContext';
import { useTranslation } from 'react-i18next';
import ResultsTable from './ApplyDenouncementTable';
import { getIsProcessing } from '../../../services/notifications/index';
import { enqueueSnackbar } from 'notistack';
import PlatformIcon from '../../../components/PlatformIcon';
import ClassificationTypeSelect from '../components/ClassificationTypeSelect';
import { TagType } from '../../../interfaces';
import { OfferToolTip } from '../../../helpers';
import { Chip, Typography } from '@mui/material';
import { Line } from '../../../styles';
import NotificationModal from '../components/NotificationModal';
import { getOffersByElasticSearch } from '../../../services/classifications';

interface Props {
	selectedPlatform: any;
}

const FetchDenunciationData: React.FC<Props> = ({
	selectedPlatform,
}: Props) => {
	const { t } = useTranslation();
	const {
		selectedClient,
		updateCursorMode,
		userData,
		selectedTheme,
		tagsActiveClient,
	} = usePO();
	const { token } = useAuth0();
	setupRequestToken(api, token);

	const [modalOpen, setModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [selectedOfferData, setSelectedOfferData] = useState(null);
	const [selectedClassificationType, setSelectedClassificationType] =
		useState(null);
	const [selectedImages, setSelectedImages] = useState<any>([]);
	const [reloadTable, setReloadTable] = useState(1);

	useEffect(() => {
		fetchProcessingCount();
		// eslint-disable-next-line
	}, [selectedClient.id]);

	const handleReloadTable = () => {
		setReloadTable(reloadTable + 1);
	};

	let isCustomerAnalyst = false;
	if (userData.roles?.indexOf('Analista do cliente') !== -1) {
		isCustomerAnalyst = true;
	}

	const [showSuspects, setShowSuspects] = useState(isCustomerAnalyst);

	const renderLoading = (isLoading) => {
		switch (isLoading) {
			case true:
				updateCursorMode('wait');
				break;
			case false:
				updateCursorMode('inherit');
				break;
			default:
				updateCursorMode('inherit');
				break;
		}
	};

	const fetchProcessingCount = async () => {
		const response = await getIsProcessing(selectedClient?.id);
		if (response && response.success) {
			if (response.data.length !== 0 && response.data.CLASSIFICADA)
				enqueueSnackbar(
					`${t('Notificações.Tabs.Classificadas.Existem')} ${
						response.data.CLASSIFICADA
					} ${t(
						'Notificações.Tabs.Classificadas.ofertas classificadas em processamento!'
					)}`,
					{
						variant: 'success',
					}
				);
		} else {
			return null;
		}
	};

	const fetchOfferByElasticSearch = async (id) => {
		setLoading(true);
		const query = `{"preference":"ReactiveListResult"}\n{"query":{"bool":{"must":[{"match":{"offerID": "${id}"}}]}},"size": 20}\n`;
		const response = await getOffersByElasticSearch(query);
		if (response?.success === true) {
			const offerData = response.data.responses[0].hits.hits[0]._source;
			setSelectedOfferData(offerData);
			setSelectedImages(offerData.images);
			setLoading(false);
		}
		setLoading(false);
	};

	const handleShowSuspects = () => {
		setShowSuspects(!showSuspects);
		handleReloadTable();
	};

	const renderOfferImage = (item) => {
		const handleClickImage = async () => {
			setLoading(true);
			await fetchOfferByElasticSearch(item.offer.offerID);
			setSelectedClassificationType(item);
			setModalOpen(true);
			setLoading(false);
		};

		return (
			<div>
				<PlatformIcon
					data={item.offer.thumb}
					dataType="image"
					size={50}
					borderRadius={'50%'}
					onClick={handleClickImage}
					loading={loading}
					cursor="pointer"
				/>
			</div>
		);
	};

	const renderChangeReasonDenouncement = (rowdata) => (
		<ClassificationTypeSelect
			rowData={rowdata}
			handleReload={handleReloadTable}
		/>
	);

	const renderTagsForClassification = (tagsToDisplay) => {
		return tagsToDisplay.length > 0 ? (
			<OfferToolTip
				arrow
				title={tagsToDisplay.map((tag) => (
					<Chip
						key={tag.name}
						label={tag.name}
						variant="outlined"
						size={'medium'}
						style={{
							maxWidth: '98%',
							margin: 2,
							backgroundColor: tag.badgeColor,
							borderColor: selectedTheme.id === 'dark' ? '' : '#e8e8e8',
							color: tag.textColor,
							borderRadius: 4,
							fontSize: 15,
						}}
					/>
				))}
			>
				<Line
					style={{
						columnGap: 2,
						justifyContent: 'center',
						display: 'flex',
						paddingLeft: tagsToDisplay.length > 1 ? 5 : 0,
					}}
				>
					<Chip
						key={tagsToDisplay[0].name}
						label={tagsToDisplay[0].name}
						variant="outlined"
						size={'medium'}
						style={{
							maxWidth: 80,
							margin: 2,
							backgroundColor: tagsToDisplay[0].badgeColor,
							borderColor: selectedTheme.id === 'dark' ? '' : '#e8e8e8',
							color: tagsToDisplay[0].textColor,
							borderRadius: 4,
							fontSize: 15,
						}}
					/>
					{tagsToDisplay.length > 1 ? (
						<Typography
							key={tagsToDisplay[0].name}
							style={{
								marginBottom: -3,
								fontSize: 10,
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorMedium
										: '',
							}}
						>{`+${tagsToDisplay.length - 1}`}</Typography>
					) : null}
				</Line>
			</OfferToolTip>
		) : (
			<Typography>-</Typography>
		);
	};

	const renderResults = (data: any, error: boolean, loading: boolean) => {
		if (selectedClient?.id !== undefined) {
			renderLoading(loading);
		}
		let tableData: any[] = [];
		if (data) {
			const tableDataSet = new Set();
			data.forEach((item) => {
				const tagsToDisplay: TagType[] = [];
				if (tagsActiveClient) {
					tagsActiveClient.forEach((tag) => {
						if (item.offer && item.offer.tags) {
							item.offer.tags.forEach((tagIDfromDataArray) => {
								if (tag.tagID === tagIDfromDataArray.tagID) {
									tagsToDisplay.push(tag);
								}
							});
						}
					});
				}
				const tableItem = {
					...item,
					title: item.offer?.title,
					price: item.offer?.currency
						? parseFloat(item.offer?.price).toLocaleString(userData.i18nID, {
								style: 'currency',
								currency: item.offer.currency,
								minimumFractionDigits: 2,
							})
						: item.offer?.price,
					thumb: renderOfferImage(item),
					author: item.author.authorName,
					id: item.offerClassification.offerClassificationID,
					created: item.created,
					tags: renderTagsForClassification(tagsToDisplay),
					denouncerName: `${item.denouncer.denouncerName} (${item.denouncer.denouncerEmail})`,
					denouncerEmail: item.denouncer.denouncerEmail,
					observation: item.offerClassification.observation,
					classificationType: renderChangeReasonDenouncement(item),
					classificationTypeID: item.classificationType.classificationTypeID,
					platform: item.offer.platformINcountryID.replace('|', ' - '),
					link: item.offer.permalink,
					categories: item.offer.categories?.name,
					offerClassificationID: item.offerClassification.offerClassificationID,
					platformID: item.offer.platformINcountryID,
				};
				tableDataSet.add(tableItem);
			});

			tableData = Array.from(tableDataSet);
		}

		return (
			<>
				<ResultsTable
					data={tableData}
					loading={loading}
					error={error}
					reloadTable={handleReloadTable}
					handleShowSuspects={handleShowSuspects}
					showSuspects={showSuspects}
				/>
				<NotificationModal
					rowData={selectedClassificationType}
					item={selectedOfferData}
					images={selectedImages}
					open={modalOpen}
					setOpen={setModalOpen}
					handleReload={handleReloadTable}
				/>
			</>
		);
	};

	const allPlatformQuery = () => {
		if (showSuspects) {
			return {
				query: {
					bool: {
						must: [
							{
								term: {
									'offerClassification.denounceStatusID': 'CLASSIFICADA',
								},
							},
							{ term: { 'offerClassification.clientID': selectedClient?.id } },
							{ term: { 'classificationType.classificationTypeID': 'suspt' } },
							{ term: { 'classificationType.ISgood': false } },
						],
						must_not: { term: { ISprocessing: true } },
					},
				},
				size: 0,
			};
		} else {
			return {
				query: {
					bool: {
						must: [
							{
								term: {
									'offerClassification.denounceStatusID': 'CLASSIFICADA',
								},
							},
							{ term: { 'offerClassification.clientID': selectedClient?.id } },
							{ term: { 'classificationType.ISgood': false } },
						],
						must_not: { term: { ISprocessing: true } },
					},
				},
				size: 0,
			};
		}
	};

	const singlePlatformQuery = () => {
		if (showSuspects) {
			return {
				query: {
					bool: {
						must: [
							{
								term: {
									'offerClassification.denounceStatusID': 'CLASSIFICADA',
								},
							},
							{ term: { 'offerClassification.clientID': selectedClient?.id } },
							{ term: { 'classificationType.classificationTypeID': 'suspt' } },
							{
								term: { 'classificationType.ISgood': false },
							},
							{ term: { 'offer.platformINcountryID': selectedPlatform } },
						],
						must_not: { term: { ISprocessing: true } },
					},
				},
				size: 0,
			};
		} else {
			return {
				query: {
					bool: {
						must: [
							{
								term: {
									'offerClassification.denounceStatusID': 'CLASSIFICADA',
								},
							},
							{ term: { 'offerClassification.clientID': selectedClient?.id } },
							{
								term: { 'classificationType.ISgood': false },
							},
							{ term: { 'offer.platformINcountryID': selectedPlatform } },
						],
						must_not: { term: { ISprocessing: true } },
					},
				},
				size: 0,
			};
		}
	};

	return (
		<>
			<MultiDropdownList
				componentId="headerPlatformSelector"
				dataField="offer.platformINcountryID"
				size={10000}
				sortBy="asc"
				queryFormat="or"
				URLParams={false}
				style={{
					height: '100%',
					width: 250,
					boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
					padding: 0,
					borderRadius: 4,
					display: 'none',
				}}
			/>
			<ReactiveList
				style={{ height: '100%', width: '100%' }}
				key={selectedPlatform + reloadTable}
				componentId="ReactiveListResult"
				dataField="*"
				size={10000}
				pagination={false}
				stream={false}
				defaultQuery={
					selectedPlatform === '*' ? allPlatformQuery : singlePlatformQuery
				}
				showLoader={false}
				react={{
					and: [
						'tagsSelect',
						'searchbox',
						'headerPlatformSelector',
						'titleSearch',
						'permalinkSearch',
						'offerIDSearch',
						'sellerSearch',
						'tagSearch',
						'dateRangeSearch',
						'classificationTypeSearch',
						'sourceSearch',
						'platformSearch',
						'denouncerSearch',
						'countrySearch',
						'stateSearch',
						'citySearch',
						'observationSearch',
						'categoriesSearch',
					],
				}}
				renderResultStats={() => {
					return null;
				}}
				renderNoResults={() => null}
				render={({ loading, error, data }) => {
					return renderResults(data, error, loading);
				}}
			/>
		</>
	);
};

export default FetchDenunciationData;
