import React, { useState } from 'react';
import { IconButton, Zoom } from '@mui/material';
import {
	Dns,
	FiberManualRecord,
	FiberManualRecordOutlined,
} from '@mui/icons-material/';
import { useHistory } from 'react-router-dom';
import { OfferToolTip } from '../../../../helpers';
import { usePanelControls } from '../../../../hooks/PanelControls';
import { useViewSearch } from '../../../../hooks/ViewSearch';
import { useTranslation } from 'react-i18next';
import { usePO } from '../../../../utils/POContext';
import { getDomainsFilters } from '../../../../services/domains';

interface Props {
	rowData: any;
}

const OpenCurrentSearch: React.FC<Props> = ({ rowData }: Props) => {
	const { selectedTheme, selectedClient } = usePO();
	const { t } = useTranslation();
	const { setSaveTagID, setSaveINcountryID } = useViewSearch();
	const { setFilterBarStates } = usePanelControls();
	const history = useHistory();

	const [isLoading, setIsLoading] = React.useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [filteredData, setFilteredData] = useState<any[]>([]);

	const handleOpenSavedSearch = async () => {
		if (rowData.searchTypeID === 'DOMINIOS') {
			setIsLoading(true);
			history.push(`/monitoramento?searchID=${rowData.searchID}`);

			setTimeout(async () => {
				try {
					const payload = {
						searchID: rowData.searchID,
						clientID: selectedClient.id,
						itemsPerPage: 999999999,
						orderField: 'id',
						ordination: 'ASC',
					};

					const response = await getDomainsFilters(payload);
					if (response?.success === true) {
						const data = response.data.hits;

						const filteredData = data.filter(
							(item: any) => item.searchID === rowData.searchID
						);
						setFilteredData(filteredData);
					} else {
						console.error('Falha na resposta:', response?.message);
					}
				} catch (error) {
					console.error('Erro ao buscar dados do endpoint:', error);
				} finally {
					setIsLoading(false);
				}
			}, 3000);
		} else {
			setSaveTagID(`${rowData.tagID}`);
			setSaveINcountryID([`${rowData.platformINcountryID}`]);
			setFilterBarStates(true);

			history.push('/classificacoes');
		}
	};

	const renderLoadingAnimation = () => {
		setTimeout(() => {
			setIsLoading(!isLoading);
		}, 400);
		return isLoading ? (
			<FiberManualRecord
				style={{
					color: 'green',
					fontSize: 20,
					marginTop: 6,
					marginRight: -5,
					marginLeft: -3,
				}}
			/>
		) : (
			<FiberManualRecordOutlined
				style={{
					color: 'green',
					fontSize: 20,
					marginTop: 6,
					marginRight: -5,
					marginLeft: -3,
				}}
			/>
		);
	};

	return rowData.inProgress ? (
		<div style={{ paddingInline: 12 }}>{renderLoadingAnimation()}</div>
	) : (
		<OfferToolTip
			title={`${t(
				'Buscas.Tabs.Buscas Salvas.Visualizar resultados desta busca'
			)}`}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				onClick={handleOpenSavedSearch}
				style={{ padding: '10px 5px' }}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<Dns
					style={{
						fontSize: 22,
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);
};

export default OpenCurrentSearch;
