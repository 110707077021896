import React, { useEffect, useState } from 'react';
import {
	Button,
	Dialog,
	IconButton,
	MenuItem,
	Select,
	Typography,
	Zoom,
} from '@mui/material';
import { AddCircleRounded, CloseRounded } from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import '../../../../i18n';
import countries from 'i18n-iso-countries';
import { usePO } from '../../../../utils/POContext';
import { api, isAxiosError, setupRequestToken } from '../../../../utils/api';
import { useAuth0 } from '../../../../react-auth0-spa';
import { OfferToolTip } from '../../../../helpers';
import OfferTable from '../../../../components/OfferTable';
import { Line } from '../../../../styles';
import { makeStyles } from '@mui/styles';
import { ErrorResponse } from '../../../../interfaces';

interface Props {
	setSelectedPlatforms: (data) => void;
	selectedPlatforms: any;
}

const PlatformSelector: React.FC<Props> = ({
	setSelectedPlatforms,
	selectedPlatforms,
}: Props) => {
	const { selectedTheme, userData } = usePO();
	const { token } = useAuth0();
	setupRequestToken(api, token);
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [availablePlatforms, setAvailablePlatforms] = useState<any[]>();

	const [platformsINcountries, setPlatformsINcoutries] = useState<any[]>();

	const [tableData, setTableData] = useState<any[]>([]);
	const [countryFilter, setCountryFilter] = React.useState('allCountries');

	const useStyles = makeStyles(() => ({
		selectCountries: {
			backgroundColor:
				selectedTheme.id === 'dark'
					? selectedTheme.overlay3dp
					: selectedTheme.foreground,
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
			borderBottom: `1px solid ${
				selectedTheme.id === 'dark'
					? selectedTheme.textColorHigh
					: selectedTheme.border
			}`,
			'&:focus': {
				backgroundColor:
					selectedTheme.id === 'dark'
						? selectedTheme.textColorHigh
						: selectedTheme.foreground,
			},
			'&:hover': {
				backgroundColor:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay3dp
						: selectedTheme.foreground,
				borderBottom: `1px solid ${
					selectedTheme.id === 'dark'
						? selectedTheme.textColorHigh
						: selectedTheme.border
				}`,
			},
			'& .MuiSvgIcon-root': {
				color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
			},
		},
	}));

	const classes = useStyles();

	const handleChange = (event) => {
		setCountryFilter(event.target.value);
	};

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			setLoading(true);
			const platformsData = await api.get('/platforms');
			setAvailablePlatforms(platformsData.data);
			const platformsINcountryData = await api.get(
				'/platformINcountry?hasCrawler=true&ISactive=true'
			);
			setPlatformsINcoutries(platformsINcountryData.data);
			setLoading(false);
		} catch (error) {
			if (isAxiosError(error)) {
				const errorResponse = error.response as ErrorResponse | undefined;
				if (errorResponse && errorResponse.data) {
					return {
						success: false,
						status: errorResponse.status,
						message: errorResponse.data.detail,
						data: [],
					};
				}
			}
		}
	};

	countries.registerLocale(
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		require('i18n-iso-countries/langs/pt.json')
	);
	countries.registerLocale(
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		require('i18n-iso-countries/langs/en.json')
	);
	countries.registerLocale(
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		require('i18n-iso-countries/langs/es.json')
	);

	const colsConfig = [
		{
			columnTitle: t('Buscas.Tabs.Nova Busca.Nome'),
			fieldName: 'name',
			translateColumnData: false,
			alignTitle: 'center',

			cellWidth: 100,
		},
		{
			columnTitle: t('Buscas.Tabs.Nova Busca.País'),
			fieldName: 'country',
			translateColumnData: true,
			alignTitle: 'center',

			cellWidth: 200,
		},
		{
			columnTitle: t('Buscas.Tabs.Nova Busca.Descrição'),
			fieldName: 'description',
			translateColumnData: false,
			alignTitle: 'center',

			cellWidth: 200,
		},
	];

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
		setCountryFilter('allCountries');
	};

	const buildTableData = () =>
		availablePlatforms?.map((platform) => {
			if (platformsINcountries) {
				platformsINcountries?.map((inCountry) => {
					if (inCountry.platformID === platform.id) {
						const newPlatform = {
							name: platform.name,
							country: countries.getName(
								inCountry.countryID,
								userData.i18nID.substring(0, 2),
								{
									select: 'official',
								}
							),
							description: platform.description,
							id: platform.id,
							inCountryID: inCountry.id,
						};
						setTableData((dataINtable) => [...dataINtable, newPlatform]);
					}
				});
			}
		});

	if (tableData.length === 0) {
		buildTableData();
	}

	const filteredTableData = tableData.filter((data) => {
		if (countryFilter === 'allCountries') return data;
		if (data.country?.includes(countryFilter)) {
			return data;
		}
		return null;
	});

	const renderCustomFilter = () => {
		const countriesFilter: any = [];

		tableData.map((el) => {
			if (countriesFilter.indexOf(el.country) === -1) {
				countriesFilter.push(el.country);
			}
			return null;
		});

		return (
			<Line>
				<Typography
					style={{
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					}}
				>
					{t('Buscas.Tabs.Nova Busca.País')}:
				</Typography>
				<Select
					variant="standard"
					onChange={handleChange}
					value={countryFilter}
					classes={{
						root: classes.selectCountries,
					}}
					MenuProps={{
						MenuListProps: {
							disablePadding: true,
						},
					}}
					style={{
						marginLeft: 15,
						overflow: 'hidden',
					}}
					sx={{
						'.MuiSelect-select': {
							padding: '5px 10px',
							background:
								selectedTheme.id === 'dark'
									? selectedTheme.overlay3dp
									: selectedTheme.foreground,
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
							'&:hover': {
								background:
									selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
							},
						},
						'.MuiSelect-icon': {
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
						},
						'& [aria-expanded=true]': {
							background:
								selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
						},
					}}
					inputProps={{
						MenuProps: {
							PaperProps: {
								sx: {
									background:
										selectedTheme.id === 'dark'
											? selectedTheme.overlay3dp
											: selectedTheme.foreground,
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorHigh
											: '',
								},
							},
						},
					}}
				>
					<MenuItem
						value={'allCountries'}
						sx={{
							backgroundColor:
								selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
						}}
					>
						{t('Buscas.Tabs.Nova Busca.Todos')}
					</MenuItem>
					{countriesFilter.map((el) => (
						<MenuItem
							key={el}
							value={el}
							sx={{
								background:
									selectedTheme.id === 'dark' && selectedTheme.overlay3dp,
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
								'&:hover': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.overlay8dp,
								},
								'&.Mui-selected': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.overlay8dp,
								},
							}}
						>
							{el}
						</MenuItem>
					))}
				</Select>
			</Line>
		);
	};

	const renderCustomHeaderComponent = () => (
		<IconButton
			style={{ marginRight: '-15px' }}
			onClick={handleClose}
			sx={{
				'&:hover': {
					backgroundColor:
						selectedTheme.id === 'dark' && selectedTheme.primaryLight,
				},
			}}
		>
			<CloseRounded
				style={{
					color: selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
				}}
			/>
		</IconButton>
	);

	const renderButton = () => (
		<Button
			type="button"
			size="small"
			onClick={handleClose}
			style={{
				color:
					selectedTheme.id === 'dark'
						? selectedTheme.textColorHigh
						: selectedTheme.foreground,
				background: selectedTheme.primaryDark,
				border: `1px solid ${
					selectedTheme.id === 'dark' ? selectedTheme.footerLine : ''
				}`,
			}}
		>
			{t('Ok')}
		</Button>
	);

	return (
		<div>
			<OfferToolTip
				title={`${t('Buscas.Tabs.Nova Busca.Adicionar plataforma')}`}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<IconButton
					onClick={handleOpen}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<AddCircleRounded
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorMedium
									: selectedTheme.primaryDark,
							fontSize: 20,
						}}
					/>
				</IconButton>
			</OfferToolTip>
			<Dialog
				onClose={(reason) => {
					if (reason !== 'backdropClick') {
						handleClose();
					}
				}}
				aria-labelledby="simple-dialog-title"
				open={open}
				scroll="body"
				maxWidth="lg"
				fullWidth
				style={{ cursor: loading ? 'wait' : 'pointer' }}
			>
				<OfferTable
					tableData={filteredTableData}
					colsData={colsConfig}
					selectedTableItems={selectedPlatforms}
					setSelectedTableItems={setSelectedPlatforms}
					checkboxes
					CustomHeaderComponent={renderCustomHeaderComponent}
					windowHeader
					windowTitle={t('Buscas.Tabs.Nova Busca.Plataformas')}
					tableFooter
					selectAll
					tableFooterButton={renderButton()}
					customRightFooterComponent={renderCustomFilter()}
					clickableRows
				/>
			</Dialog>
		</div>
	);
};
export default PlatformSelector;
