import React, { useEffect, useState } from 'react';
import {
	Autocomplete,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Menu,
	MenuItem,
	TextField,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Line } from '../../styles';
import { usePO } from '../../utils/POContext';
import { useSnackbar } from 'notistack';
import { getEntities, getEntityOnAuthor } from '../../services/entities';
import { LinkOff, MoreVert, CloseRounded } from '@mui/icons-material/';
import {
	bindAuthorAndEntity,
	unbindAuthorFromEntity,
} from '../../services/authors';
import LoadingDots from '../LoadingDots';
import { OfferToolTip } from '../../helpers';
import { makeStyles } from '@mui/styles';
import { EntityType } from '../../interfaces';

type displayOptions = 'card' | 'menu';

interface Props {
	authorData: any;
	boundTo?: EntityType;
	setBoundTo: (entity: EntityType | null) => void;
	display?: displayOptions;
}

const AuthorBindModal: React.FC<Props> = ({
	authorData,
	boundTo,
	display,
	setBoundTo,
}: Props) => {
	const { t } = useTranslation();
	const { selectedTheme, updateCursorMode } = usePO();
	const { enqueueSnackbar } = useSnackbar();

	const [openModal, setOpenModal] = useState(false);
	const [selectedEntity, setSelectedEntity] = useState<EntityType | null>(null);
	const [dialogFunction, setDialogFunction] = useState<string>('');
	const [availableEntities, setAvailableEntities] = useState<any[]>([]);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [loading, setLoading] = useState(false);
	const [boundEntity, setBoundEntity] = useState<EntityType | null>(null);

	useEffect(() => {
		if (openModal === true) {
			fetchAvailableEntities();
		}
		if (boundTo !== null && boundTo !== undefined) {
			setBoundEntity(boundTo);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openModal, boundTo]);

	const fetchAvailableEntities = async () => {
		if (dialogFunction === 'bind') {
			setLoading(true);
			await getEntities({}).then((response: any) => {
				if (response.success === true) {
					if (response.data.hits.length > 0) {
						setAvailableEntities(response.data.hits);
					}
				}
				if (response.success === false) {
					enqueueSnackbar(response.message, { variant: 'error' });
				}
			});
			setLoading(false);
		}
	};

	const fetchBoundEntity = async () => {
		if (boundTo === null || boundEntity === null) {
			setLoading(true);
			updateCursorMode('wait');
			const response: any = await getEntityOnAuthor(authorData.authorID);
			if (response.success === true) {
				setBoundEntity(response.data);
			}
			if (response.success === false) {
				setBoundEntity(null);
			}
			setLoading(false);
			updateCursorMode('default');
		}
	};

	const useStyles = makeStyles(() => ({
		paper: {
			background: selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
		},
		option: {
			backgroundColor:
				selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
		},
		autoCompleteStyle: {
			background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
		},
	}));

	const classes = useStyles();

	const handleOpenMenu = (event) => {
		setAnchorEl(event.currentTarget);
		fetchBoundEntity();
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	const handleOpenModal = (dialogFunction) => {
		setDialogFunction(dialogFunction);
		setAnchorEl(null);
		setOpenModal(true);
	};

	const handleClose = () => {
		setOpenModal(false);
		setAvailableEntities([]);
	};

	const handleSubmit = async (action: string) => {
		setLoading(true);
		if (action === 'bind' && selectedEntity && selectedEntity !== null) {
			const response: any = await bindAuthorAndEntity(
				authorData.authorID,
				selectedEntity.id
			);
			if (response && response.success === true) {
				enqueueSnackbar(
					t(
						`Autores.${response.message}` ||
							'Autores.Autor vinculado com sucesso'
					),
					{
						variant: 'success',
					}
				);
				setBoundTo(selectedEntity);
				setBoundEntity(selectedEntity);
				handleClose();
			} else {
				enqueueSnackbar(
					t(response.message || 'Não foi possível completar a requisição'),
					{ variant: 'error' }
				);
			}
		}
		if (action === 'unbind' && boundEntity && boundEntity !== null) {
			const response: any = await unbindAuthorFromEntity(
				`${authorData.authorID}|${boundEntity.id}`
			);

			if (response && response.success === true) {
				enqueueSnackbar(
					t(
						`Autores.${response.message}` ||
							'Autores.Autor desvinculado com sucesso'
					),
					{
						variant: 'success',
					}
				);
				setBoundTo(selectedEntity);
				setBoundEntity(selectedEntity);
				handleClose();
			} else {
				enqueueSnackbar(
					t(response.message || 'Não foi possível completar a requisição'),
					{ variant: 'error' }
				);
			}
		}
		setLoading(false);
	};

	const handleSelectEntity = (event, newValue) => {
		setSelectedEntity(newValue);
	};

	const renderThemeTextHeader = () => {
		switch (selectedTheme.id) {
			case 'main':
				return selectedTheme.primary;
			case 'dark':
				return selectedTheme.textColorHigh;
			default:
				return selectedTheme.foreground;
		}
	};

	const renderThemeCloseIcon = () => {
		switch (selectedTheme.id) {
			case 'main':
				return selectedTheme.primaryDark;
			case 'dark':
				return selectedTheme.textColorHigh;
			default:
				return selectedTheme.foreground;
		}
	};

	const renderMenuItemText = () =>
		loading ? (
			<Line style={{ width: 250, justifyContent: 'center' }}>
				<LoadingDots width={40} />
			</Line>
		) : (
			t('Autores.Não vinculado a nenhuma entidade')
		);

	const renderMenu = () => (
		<Menu
			id="simple-menu"
			anchorEl={anchorEl}
			keepMounted
			open={Boolean(anchorEl)}
			onClose={handleCloseMenu}
			style={{ display: loading ? 'none' : 'block' }}
			classes={{
				paper: classes.paper,
			}}
			sx={{
				'.MuiMenuItem-root': {
					background: selectedTheme.id === 'dark' && selectedTheme.overlay6dp,
					color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.overlay8dp,
					},
					'&.Mui-selected': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.overlay8dp,
					},
					'&.Mui-disabled': {
						color: selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
					},
				},
			}}
		>
			<MenuItem
				data-testid="bindEntity"
				disabled={boundEntity !== null}
				onClick={() => handleOpenModal('bind')}
			>
				{loading ? (
					<Line style={{ width: 250, justifyContent: 'center' }}>
						<LoadingDots width={40} />
					</Line>
				) : (
					t('Autores.Vincular a uma entidade')
				)}
			</MenuItem>
			<MenuItem
				data-testid="unbindEntity"
				disabled={boundEntity === null}
				onClick={() => handleOpenModal('unbind')}
			>
				{boundEntity !== null
					? t('Autores.Desvincular de ') + boundEntity.name
					: renderMenuItemText()}
			</MenuItem>
		</Menu>
	);

	const renderDialogHeader = () =>
		dialogFunction === 'bind' ? (
			<DialogTitle
				style={{
					width: 500,
					height: 60,
					background:
						selectedTheme.id === 'dark'
							? selectedTheme?.tableHead
							: selectedTheme.gradient,
					display: 'flex',
				}}
				id="max-width-dialog-title"
			>
				<Line
					style={{
						justifyContent: 'space-between',
					}}
				>
					<Typography
						data-testid="selectEntity"
						noWrap
						style={{
							color: renderThemeTextHeader(),
							fontSize: 20,
							fontWeight: 'bold',
							maxWidth: '90%',
						}}
					>
						{t('Autores.Selecione uma Entidade')}
					</Typography>
					<IconButton
						onClick={handleClose}
						style={{ marginRight: '-16px' }}
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<CloseRounded
							style={{
								color: renderThemeCloseIcon(),
							}}
						/>
					</IconButton>
				</Line>
			</DialogTitle>
		) : null;

	const renderDialogContent = () => (
		<DialogContent
			style={{
				padding: '30px',
				background:
					selectedTheme.id === 'dark'
						? selectedTheme?.overlay6dp
						: selectedTheme?.foreground,
			}}
		>
			{dialogFunction === 'bind' ? (
				<Autocomplete
					data-testid="selectEntitySelector"
					id="entity-select"
					options={availableEntities}
					value={selectedEntity}
					onChange={handleSelectEntity}
					autoHighlight
					autoComplete
					autoSelect
					disableClearable
					loadingText={t('Carregando...')}
					selectOnFocus
					getOptionLabel={(option) => option.name}
					renderOption={(props, option: EntityType) => (
						<li {...props}>{option.name}</li>
					)}
					classes={{
						option: classes.option,
						root: classes.autoCompleteStyle,
						paper: classes.autoCompleteStyle,
					}}
					sx={{
						width: '100%',
						cursor: 'pointer',
						color:
							selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
						'& .MuiInputBase-root': {
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
						'.MuiSvgIcon-root ': {
							fill:
								selectedTheme.id === 'dark'
									? selectedTheme?.textColorMedium
									: '',
						},
						background:
							selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
					}}
					renderInput={(params) => (
						<TextField
							style={{
								background:
									selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
								color:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorMedium
										: '',
							}}
							{...params}
							variant={'outlined'}
							name="authorBind"
							data-testid="authorBind"
							size="small"
							inputProps={{
								...params.inputProps,
								style: {
									color:
										selectedTheme.id === 'dark'
											? selectedTheme?.textColorMedium
											: '',
								},
							}}
						/>
					)}
				/>
			) : (
				<Typography
					data-testid="unselectEntity"
					style={{
						color:
							selectedTheme.id === 'dark' ? selectedTheme?.textColorMedium : '',
					}}
					variant="h6"
				>
					{`${t('Autores.Deseja desvincular o autor')} ${
						authorData.authorName
					}? `}
				</Typography>
			)}
		</DialogContent>
	);

	const renderFooter = () => (
		<DialogActions
			style={{
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark'
						? selectedTheme.footerLine
						: selectedTheme.disabled,
				padding: '15px 20px 15px 15px',
				background:
					selectedTheme.id === 'dark'
						? selectedTheme?.overlay3dp
						: selectedTheme?.foreground,
			}}
		>
			<Line
				style={{
					justifyContent: 'space-between',
				}}
			>
				<Button
					size="small"
					data-testid="closeButton"
					disabled={loading}
					onClick={handleClose}
					style={{
						color: loading ? 'gray' : selectedTheme.error,
					}}
				>
					{t('Autores.Cancelar')}
				</Button>

				{dialogFunction === 'bind' ? (
					<Button
						size="small"
						data-testid="bindButton"
						onClick={() => handleSubmit('bind')}
						style={{
							width: 90,
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.foreground,
							background: selectedTheme.primaryDark,
							border:
								selectedTheme.id === 'dark'
									? `1px solid ${selectedTheme.footerLine}`
									: '',
						}}
					>
						{loading ? (
							<CircularProgress
								size={20}
								style={{
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorHigh
											: selectedTheme.foreground,
								}}
							/>
						) : (
							t('Autores.Vincular')
						)}
					</Button>
				) : (
					<Button
						size="small"
						data-testid="unbindButton"
						onClick={() => handleSubmit('unbind')}
						style={{
							width: 110,
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.foreground,
							background: selectedTheme.primaryDark,

							border:
								selectedTheme.id === 'dark'
									? `1px solid ${selectedTheme.footerLine}`
									: '',
						}}
					>
						{loading ? (
							<CircularProgress
								size={20}
								style={{
									color: selectedTheme.primaryDark,
								}}
							/>
						) : (
							t('Autores.Desvincular')
						)}
					</Button>
				)}
			</Line>
		</DialogActions>
	);

	const renderActionButton = () => {
		switch (display) {
			case 'card':
				return (
					<OfferToolTip title={`${t('Autores.Desvincular')}`} arrow>
						<IconButton
							sx={{
								marginLeft: '10px',
								'&:hover': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.primaryLight,
								},
							}}
							size="small"
							onClick={() => handleOpenModal('unbind')}
							data-testid="moreVertButton"
						>
							<LinkOff
								style={{
									fontSize: 18,
									marginTop: -2,
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorHigh
											: selectedTheme.primaryDark,
								}}
							/>
						</IconButton>
					</OfferToolTip>
				);
			case 'menu':
				return (
					<IconButton
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
						data-testid="moreVertButton"
						size="small"
						aria-label="vert-bind-menu"
						aria-controls="long-menu"
						aria-haspopup="true"
						onClick={handleOpenMenu}
					>
						<MoreVert
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: '',
								cursor: loading ? 'wait' : 'pointer',
							}}
						/>
					</IconButton>
				);
			default:
				break;
		}
	};

	return (
		<>
			{renderActionButton()}
			<Dialog open={openModal} scroll="body">
				{renderDialogHeader()}
				{renderDialogContent()}
				{renderFooter()}
			</Dialog>
			{renderMenu()}
		</>
	);
};

export default AuthorBindModal;
