import React, { useEffect, useState } from 'react';
import { IconButton, Typography, Zoom } from '@mui/material';
import {
	FileCopyOutlined,
	PlaylistAddRounded,
	SyncRounded,
	Visibility,
	VisibilityOff,
} from '@mui/icons-material/';
import { api, setupRequestToken } from '../../../../utils/api';
import { changePanelState } from '../../../../redux/slices/panelControl';
import { Line } from '../../../../styles';
import { OfferToolTip } from '../../../../helpers';
import { useAuth0 } from '../../../../react-auth0-spa';
import { useDispatch, useSelector } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';
import { usePO } from '../../../../utils/POContext';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import ApplyDenouncementDialog from '../ApplyDenouncementDialog';
import CheckProcessingItems from '../../components/CheckProcessingItems';
import DeleteDenouncementDialog from '../../components/DeleteDenouncementDialog';
import EditDenouncementModal from '../EditDenouncementModal';

import OfferTable from '../../../../components/OfferTable';
import OpenExternalLink from '../../components/OpenExternalLink';
import SelectClient from '../../../../components/SelectClient';
import ShortCutModal from '../../../../components/ShortCutModal';

interface Props {
	data: any;
	error: boolean;
	loading: boolean;
	reloadTable?: () => void;
	handleShowSuspects?: () => void;
	showSuspects?: boolean;
}

const ApplyDenouncementTable: React.FC<Props> = ({
	data,
	error,
	loading,
	reloadTable,
	handleShowSuspects,
	showSuspects,
}: Props) => {
	const { t } = useTranslation();
	const { selectedClient, selectedTheme } = usePO();
	const { token } = useAuth0();
	setupRequestToken(api, token);
	const { enqueueSnackbar } = useSnackbar();

	useHotkeys('ctrl+alt+c', () => handleKeyShortcutCopy());
	useHotkeys('ctrl+alt+f', () => handleKeyShortcutFilter());

	const [tableData, setTableData] = useState<any[]>([]);
	const [selectedTableItems, setSelectedTableItems] = useState<any[]>([]);

	const dispatch = useDispatch();
	const panelControlList = useSelector((state: any) => state.panelControl);

	useEffect(() => {
		dispatch(changePanelState(''));
	}, [dispatch]);

	const colsData = [
		{
			columnTitle: '',
			fieldName: 'thumb',
			translateColumnData: false,
			alignTitle: 'center',
			widthFixed: 50,
			dataType: 'reactObject',
		},
		{
			columnTitle: t('Notificações.Tabs.Classificadas.Anúncio'),
			fieldName: 'title',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 200,
		},
		{
			columnTitle: t('Notificações.Tabs.Classificadas.Preço'),
			fieldName: 'price',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 80,
		},
		{
			columnTitle: t('Notificações.Tabs.Classificadas.Autor'),
			fieldName: 'author',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 80,
		},
		{
			columnTitle: t('Notificações.Tabs.Classificadas.Plataforma'),
			fieldName: 'platform',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 80,
		},
		{
			columnTitle: t('Notificações.Tabs.Classificadas.Etiquetas'),
			fieldName: 'tags',
			translateColumnData: true,
			alignTitle: 'center',
			cellWidth: 150,
			dataType: 'reactObject',
		},
		{
			columnTitle: t('Notificações.Tabs.Classificadas.Categoria'),
			fieldName: 'categories',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 50,
		},
		{
			columnTitle: t('Notificações.Tabs.Classificadas.Denunciante'),
			fieldName: 'denouncerName',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 120,
		},
		{
			columnTitle: t('Notificações.Tabs.Classificadas.Motivos de denúncia'),
			fieldName: 'classificationType',
			translateColumnData: true,
			alignTitle: 'center',
			cellWidth: 120,
			dataType: 'reactObject',
		},
		{
			columnTitle: t('Notificações.Tabs.Classificadas.Data'),
			fieldName: 'created',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 70,
			dataType: 'date',
		},
	];

	const ShortCutsData = [
		{
			description: t(
				'Notificações.Tabs.Classificadas.Selecionar todos as denúncias da página atual'
			),
			shortcut: ['Ctrl', 'Alt', 'A'],
		},
		{
			description: t(
				'Notificações.Tabs.Classificadas.Copiar denúncias selecionadas'
			),
			shortcut: ['Ctrl', 'Alt', 'C'],
		},
		{
			description: t(
				'Notificações.Tabs.Classificadas.Abrir/fechar barra de filtros avançados'
			),
			shortcut: ['Ctrl', 'Alt', 'F'],
		},
		{
			description: t(
				'Notificações.Tabs.Classificadas.Efetivar denúncias selecionadas'
			),
			shortcut: ['Ctrl', 'Alt', 'Enter'],
		},
	];

	const action2Props = { reloadTable: reloadTable };
	const action3Props = { reloadTable: reloadTable, tableData: tableData };

	const copyToClipboard = async () => {
		const allLinks = selectedTableItems
			.map((el) => el.link)
			.join(', \n')
			.toString();
		navigator.clipboard.writeText(allLinks);
		const itensCount = selectedTableItems.length;
		const message =
			itensCount === 1
				? `${itensCount} ${t('Item copiado com sucesso')}`
				: `${itensCount} ${t('Itens copiados com sucesso')}`;

		enqueueSnackbar(message, {
			variant: 'success',
		});
	};

	const handlePanelControlState = () => {
		panelControlList.panelControl === 'classifiedPanelControl'
			? dispatch(changePanelState(''))
			: dispatch(changePanelState('classifiedPanelControl'));
	};

	const handleKeyShortcutCopy = () => {
		if (selectedTableItems.length !== 0) {
			copyToClipboard();
		} else {
			enqueueSnackbar(t('Não há itens selecionados'), {
				variant: 'warning',
			});
		}
	};

	const handleKeyShortcutFilter = () => {
		handlePanelControlState();
	};

	const leftFooterComponent = () => (
		<>
			<OfferToolTip
				title={t('Notificações.Atualizar dados da tabela')}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<IconButton
					onClick={reloadTable}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<SyncRounded
						style={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							fontSize: 20,
						}}
					/>
				</IconButton>
			</OfferToolTip>
			{selectedTableItems.length !== 0 ? (
				<OfferToolTip
					title={`${t('Notificações.Tabs.Classificadas.Copiar para a área de transferência')}`}
					aria-label="inativo"
					enterDelay={700}
					enterNextDelay={700}
					arrow
					TransitionComponent={Zoom}
				>
					<IconButton
						disabled={selectedTableItems.length === 0 ? true : false}
						onClick={() => copyToClipboard()}
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<FileCopyOutlined
							style={{
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
								fontSize: 20,
							}}
						/>
					</IconButton>
				</OfferToolTip>
			) : null}

			<OfferToolTip
				title={
					showSuspects
						? `${t('Mostrar todos os itens')}`
						: `${t('Mostrar apenas itens suspeitos')}`
				}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<IconButton
					onClick={handleShowSuspects}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					{showSuspects ? (
						<Visibility
							style={{
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
								fontSize: 20,
							}}
						/>
					) : (
						<VisibilityOff
							style={{
								fontSize: 20,
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							}}
						/>
					)}
				</IconButton>
			</OfferToolTip>
			<OfferToolTip
				title={
					panelControlList.panelControl === 'classifiedPanelControl'
						? t('Ocultar barra de filtros')
						: t('Mostrar barra de filtros')
				}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<IconButton
					onClick={handlePanelControlState}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<PlaylistAddRounded
						style={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					/>
				</IconButton>
			</OfferToolTip>
		</>
	);

	const rightFooterComponent = () => (
		<Line style={{ justifyContent: 'flex-end' }}>
			<Typography
				sx={{
					color: selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
				}}
			>
				{t('Notificações.Tabs.Classificadas.Cliente')}:
			</Typography>
			<div style={{ width: 180, marginBottom: -5 }}>
				<SelectClient width="180px" />
			</div>
		</Line>
	);

	const renderFooterButton = () => (
		<ApplyDenouncementDialog
			setTableData={setTableData}
			data={selectedTableItems}
			setData={setSelectedTableItems}
			reloadTable={reloadTable}
		/>
	);

	const renderMessages = () =>
		// eslint-disable-next-line no-nested-ternary
		error
			? t('Notificações.Tabs.Classificadas.Erro ao receber dados')
			: loading
				? t('Carregando...')
				: t('Notificações.Tabs.Classificadas.Sem denúncias para este cliente');

	return (
		<>
			<OfferTable
				colsData={colsData}
				tableData={data}
				tableActions
				tableFooter
				selectAll
				selectedTableItems={selectedTableItems}
				setSelectedTableItems={setSelectedTableItems}
				checkboxes
				ternaryCheckbox
				customNoDataMessage={
					selectedClient
						? renderMessages()
						: t('Notificações.Tabs.Classificadas.Selecione um cliente')
				}
				customLeftFooterComponent={leftFooterComponent()}
				customRightFooterComponent={rightFooterComponent()}
				customInfoButton={
					<ShortCutModal
						headerTitle={t(
							'Notificações.Tabs.Classificadas.Aba de Classificadas'
						)}
						data={ShortCutsData}
					/>
				}
				ActionComponent1={OpenExternalLink}
				ActionComponent2={EditDenouncementModal}
				ActionComponent2Props={action2Props}
				ActionComponent3={DeleteDenouncementDialog}
				ActionComponent3Props={action3Props}
				tableFooterButton={renderFooterButton()}
			/>
			<CheckProcessingItems tableData={data} />
		</>
	);
};

export default ApplyDenouncementTable;
