import React, { useEffect, useState } from 'react';
import {
	Button,
	Typography,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	DialogTitle,
	Box,
	Divider,
	FormControlLabel,
} from '@mui/material';
import { CloseRounded } from '@mui/icons-material/';
import convertCountryName from 'i18n-iso-countries';
import { useTranslation } from 'react-i18next';
import { api, isAxiosError } from '../../../../../utils/api';
import Loading from '../../../../../components/LoadingDots';
import { Line } from '../../../../../styles';
import Panel from '../../../../../components/Panel';
import BasicInfo from '../../Panels/BasicInfo';
import { RegularPanel } from '../../../../../components/Panel/styles';
import { OfferSwitch, onlyNumbersRegex } from '../../../../../helpers';
import { usePO } from '../../../../../utils/POContext';
import {
	createNewClient,
	createNewClientBranch,
} from '../../../../../services/client';
import { useSnackbar } from 'notistack';
import { ErrorResponse } from '../../../../../interfaces';
import {
	ClientBranchType,
	ClientType,
	FormErrors,
} from '../../../../../interfaces/Clients';
import * as Yup from 'yup';
import { cnpj } from 'cpf-cnpj-validator';

convertCountryName.registerLocale(
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	require('i18n-iso-countries/langs/pt.json')
);
interface Props {
	rowData?: ClientBranchType;
	readOnly?: boolean;
	open: boolean;
	setOpen: (boolean) => void;
	refresh: () => void;
}

const ClientForm: React.FC<Props> = ({
	rowData,
	open,
	setOpen,
	refresh,
}: Props) => {
	const { selectedTheme } = usePO();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = React.useState(false);
	const [switchState, setSwitchState] = React.useState({
		mostrarTodos: false,
	});
	const [panelState, setPanelState] = React.useState(switchState.mostrarTodos);
	const [availableClients, setAvailableClients] = useState<ClientType[]>([]);

	const [newCompanyBranch, setNewCompanyBranch] = useState<string | null>(null);

	const [formErrors, setFormErrors] = useState<FormErrors>({});
	const [formData, setFormData] = useState<ClientBranchType>({
		id: rowData?.id || '',
		name: rowData?.name || '',
		parent: rowData?.parent || '',
		clientRegNumber: rowData?.clientRegNumber || '',
		clientID: rowData?.clientID || '',
		countryID: rowData?.countryID || '',
		country: rowData?.country || '',
		stateName: rowData?.stateName || '',
		cityName: rowData?.cityName || '',
		zipCode: rowData?.zipCode || '',
		streetAddress: rowData?.streetAddress || '',
		numberAddress: rowData?.numberAddress || '',
		complement: rowData?.complement || '',
		contactName: rowData?.contactName || '',
		BIID: rowData?.BIID || '',
		email1: rowData?.email1 || '',
		email2: rowData?.email2 || '',
		phone1: rowData?.phone1 || '',
		phone2: rowData?.phone2 || '',
		description: rowData?.description || '',
		ISactive: rowData?.ISactive || false,
	});

	/**
	 * Fetch table content on loading
	 */

	useEffect(() => {
		if (!rowData) {
			fetchAvailableClients();
		}
	}, [rowData]);

	const fetchAvailableClients = async () => {
		try {
			const usersDataResponse = await api.get('/clients');
			setAvailableClients(usersDataResponse.data);
			setTimeout(() => {
				// setLoading(false);
			}, 1500);
		} catch (error) {
			if (isAxiosError(error)) {
				const errorResponse = error.response as ErrorResponse | undefined;
				if (errorResponse && errorResponse.data) {
					return {
						success: false,
						status: errorResponse.status,
						message: errorResponse.data.detail,
					};
				} else {
					return {
						success: false,
						status: 500,
						message:
							'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
					};
				}
			}
		}
	};

	const handleResetForm = () => {
		setFormData({
			id: '',
			name: '',
			parent: '',
			clientRegNumber: '',
			clientID: '',
			countryID: '',
			country: '',
			stateName: '',
			cityName: '',
			zipCode: '',
			streetAddress: '',
			numberAddress: '',
			complement: '',
			contactName: '',
			BIID: '',
			email1: '',
			email2: '',
			phone1: '',
			phone2: '',
			description: '',
			ISactive: false,
		});
		setFormErrors({});
	};

	const handleClose = () => {
		setNewCompanyBranch(null);
		setOpen(false);
		handleResetForm();

		/** Closes all panels on exit */
		setTimeout(() => {
			setPanelState(false);
			setSwitchState({
				...switchState,
				mostrarTodos: false,
			});
		}, 200);
	};

	const handleNewCompanyBranch = (companyData: string) => {
		setNewCompanyBranch(companyData);
	};

	const renderPhones = () => {
		if (!formData.phone1) {
			return null;
		}
		if (!formData.phone2) {
			return [formData.phone1.replace(onlyNumbersRegex, '')];
		}
		return [
			formData.phone1.replace(onlyNumbersRegex, ''),
			formData.phone2.replace(onlyNumbersRegex, ''),
		];
	};

	const renderEmails = () => {
		if (formData.email1 === '') {
			return null;
		}
		if (formData.email2 === '') {
			return [formData.email1];
		}
		return [formData.email1, formData.email2];
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string()
			.min(3, t('Clientes.O nome precisa ter no mínimo 3 caracteres'))
			.required(t('Clientes.O nome é obrigatório')),
		countryID: Yup.string().when('parent', {
			is: (value) => !value,
			then: (schema) => schema.required(t('Clientes.O país é obrigatório')),
			otherwise: (schema) => schema.notRequired(),
		}),
		clientRegNumber: Yup.string()
			.required(t('Clientes.O número de registro ou CNPJ é obrigatório'))
			.test(
				t('Clientes.Validação de número de registro'),
				t('Clientes.Numero de CNPJ inválido'),
				(value) => {
					if (value && formData.countryID === 'BRA') {
						const schemaCnpj = Yup.string().test(
							t('Clientes.Validação de CNPJ'),
							t('Clientes.Numero de CNPJ inválido'),
							(valueCnpj) =>
								cnpj.isValid(valueCnpj || ''.replace(/[^0-9]/g, ''))
						);
						return schemaCnpj.isValidSync(value);
					}
					return true;
				}
			),
		email1: Yup.string()
			.required(t('Clientes.O email é obrigatório'))
			.email(t('Clientes.E-mail inválido')),
		email2: Yup.string().email(t('Clientes.E-mail inválido')),
		contactName: Yup.string()
			.min(
				3,
				t('Clientes.O nome do contato precisa ter no mínimo 3 caracteres')
			)
			.required(t('Clientes.O nome do contato é obrigatório')),
	});

	async function handleSubmit() {
		try {
			await validationSchema.validate(formData, {
				abortEarly: false,
			});
			setFormErrors({});
			handleCommitInfo();
		} catch (err) {
			const validationErrors: FormErrors = {};
			if (err instanceof Yup.ValidationError) {
				err.inner.forEach((error: Yup.ValidationError) => {
					validationErrors[error.path || ''] = error.message;
				});
				setFormErrors(validationErrors);
			}
		}
	}

	const handleCommitInfo = async () => {
		const location = formData.countryID
			? convertCountryName.getAlpha3Code(formData.countryID, 'pt')
			: '';

		const branchPayload = {
			clientID: newCompanyBranch || rowData?.id,
			countryID: location,
			clientRegNumber:
				location === 'BRA'
					? formData.clientRegNumber.replace(onlyNumbersRegex, '')
					: formData.clientRegNumber,
			zipCode:
				location === 'BRA'
					? formData.zipCode.replace(onlyNumbersRegex, '')
					: formData.zipCode,
			streetAddress: formData.streetAddress,
			numberAddress: parseInt(formData.numberAddress.trim()),
			complement: formData.complement,
			cityName: formData.cityName,
			stateName: formData.stateName,
			contactName: formData.contactName,
			parent: formData.parent,
			email: renderEmails(),
			phone: renderPhones(),
			ISactive: true,
			BIID: formData.BIID,
		};

		const clientPayload = {
			name: formData.name,
			parent: formData.parent,
			description: formData.description,
			ISactive: true,
			BIID: formData.BIID,
		};

		setLoading(true);

		try {
			/* New client and new Branch */
			if (newCompanyBranch === null) {
				const response = await createNewClient(clientPayload);
				if (response?.success === true) {
					enqueueSnackbar(t('Clientes.Cliente adicionado com sucesso'), {
						variant: 'success',
					});
					branchPayload.clientID = response.data.id;

					if (formData.countryID) {
						try {
							const branchResponse = await createNewClientBranch(branchPayload);
							if (branchResponse?.success === true) {
								enqueueSnackbar(
									t(
										'Clientes.Relação entre cliente e país adicionada com sucesso.'
									),
									{ variant: 'success' }
								);
							} else {
								enqueueSnackbar(
									t('Clientes.Relação entre cliente e país já cadastrada.'),
									{ variant: 'warning' }
								);
							}
						} catch (error) {
							enqueueSnackbar(
								t('Clientes.Erro ao criar a filial do cliente.'),
								{
									variant: 'error',
								}
							);
						}
					}
				} else {
					enqueueSnackbar(
						t('Clientes.Cliente ou código de referência já cadastrado.'),
						{ variant: 'warning' }
					);
				}
			}

			/* New Branch in client */
			if (newCompanyBranch !== null) {
				try {
					const response = await createNewClientBranch(branchPayload);
					if (response?.success === true) {
						enqueueSnackbar(
							t(
								'Clientes.Relação entre cliente e país adicionada com sucesso.'
							),
							{ variant: 'success' }
						);
					} else {
						enqueueSnackbar(t('Clientes.Cliente já cadastrado.'), {
							variant: 'warning',
						});
					}
				} catch (error) {
					enqueueSnackbar(t('Clientes.Erro ao criar a filial do cliente.'), {
						variant: 'error',
					});
				}
			}
		} catch (error) {
			enqueueSnackbar(t('Clientes.Erro ao criar o cliente.'), {
				variant: 'error',
			});
		} finally {
			setLoading(false);
			handleClose();
			refresh();
		}
	};

	const handleSwitchChangeStyle = () => {
		if (switchState.mostrarTodos) {
			switch (selectedTheme.id) {
				case 'dark':
					return selectedTheme.textColorHigh;
				case 'main':
					return selectedTheme.primary;
				default:
					return selectedTheme.primary;
			}
		} else {
			switch (selectedTheme.id) {
				case 'dark':
					return selectedTheme.primaryDark;
				case 'main':
					return '';
				default:
					return;
			}
		}
	};

	const typographyStyleHeader = () => {
		switch (selectedTheme.id) {
			case 'main':
				return selectedTheme.primaryDark;
			case 'dark':
				return selectedTheme.textColorHigh;
			default:
				return selectedTheme.foreground;
		}
	};

	const handleBorderButton = () => {
		switch (selectedTheme.id) {
			case 'dark':
				return `1px solid ${selectedTheme.footerLine}`;
			default:
				return '';
		}
	};

	const handlePanelsSwitchChange = (event) => {
		setSwitchState({
			...switchState,
			[event.target.name]: event.target.checked,
		});
		setPanelState(event.target.checked);
	};

	const handleFormTitle = () => {
		return (
			<Typography
				noWrap
				style={{
					color: typographyStyleHeader(),
					fontSize: 20,
					fontWeight: 'bold',
					maxWidth: '90%',
				}}
			>
				{newCompanyBranch === null
					? t('Clientes.Novo Cliente')
					: t('Clientes.Nova Unidade')}
			</Typography>
		);
	};

	const renderFooter = () => (
		<DialogActions
			style={{
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
				background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
			}}
		>
			<Line
				style={{
					justifyContent: 'space-between',
					padding: 5,
				}}
			>
				<FormControlLabel
					control={
						<OfferSwitch
							size="small"
							checked={switchState.mostrarTodos}
							onClick={handlePanelsSwitchChange}
							style={{ color: handleSwitchChangeStyle() }}
							name="mostrarTodos"
							inputProps={{ 'aria-label': 'show all checkbox' }}
						/>
					}
					label={
						<Typography
							variant="subtitle2"
							sx={{
								paddingLeft: '5px',
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							}}
						>
							{t('Clientes.Expandir todos')}
						</Typography>
					}
					style={{ marginLeft: 5, marginTop: -1 }}
				/>
				{loading ? (
					<Loading width={90} height={35} loop />
				) : (
					<Button
						onClick={handleSubmit}
						variant="contained"
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.foreground,
							background: selectedTheme.primaryDark,
							border: handleBorderButton(),
						}}
					>
						{t('Clientes.Salvar')}
					</Button>
				)}
			</Line>
		</DialogActions>
	);

	return (
		<div>
			<Dialog
				open={open}
				scroll="body"
				maxWidth="md"
				fullWidth
				disableEscapeKeyDown
			>
				<DialogTitle
					style={{
						height: 60,
						background:
							selectedTheme.id === 'dark'
								? selectedTheme?.tableHead
								: selectedTheme.gradient,
						color: '#FFFFFF',
						display: 'flex',
					}}
					id="draggable-dialog-title"
				>
					<Line
						style={{
							justifyContent: 'space-between',
						}}
					>
						{handleFormTitle()}

						<IconButton
							onClick={handleClose}
							style={{ marginRight: '-16px' }}
							sx={{
								'&:hover': {
									backgroundColor: selectedTheme.hoverBackground,
								},
							}}
						>
							<CloseRounded
								style={{
									color:
										selectedTheme.id === 'main'
											? selectedTheme.primary
											: 'white',
								}}
							/>
						</IconButton>
					</Line>
				</DialogTitle>
				<DialogContent
					style={{
						padding: 0,
						background:
							selectedTheme.id === 'dark' ? selectedTheme.overlay4dp : '',
					}}
				>
					<Divider
						style={{
							background:
								selectedTheme.id === 'dark' ? selectedTheme.footerLine : '',
						}}
					/>
					<Divider
						style={{
							background:
								selectedTheme.id === 'dark' ? selectedTheme.footerLine : '',
						}}
					/>
					<RegularPanel>
						<Panel open={panelState} title={t('Clientes.Informações Básicas')}>
							<BasicInfo
								availableClientsData={availableClients}
								formData={formData}
								setFormData={setFormData}
								formErrors={formErrors}
								setFormErrors={setFormErrors}
								handleNewCompanyBranch={handleNewCompanyBranch}
							/>
						</Panel>
					</RegularPanel>
					<RegularPanel>
						<Panel open={panelState} title={t('Clientes.Planos')}>
							<Box height={100} />
						</Panel>
					</RegularPanel>
					<RegularPanel>
						<Panel open={panelState} title={t('Clientes.Direitos Protegidos')}>
							<Box height={100} />
						</Panel>
					</RegularPanel>
					<RegularPanel>
						<Panel open={panelState} title={t('Clientes.Documentos e Imagens')}>
							<Box height={100} />
						</Panel>
					</RegularPanel>
					<RegularPanel>
						<Panel
							noMargin
							open={panelState}
							title={t('Clientes.Dashboard e Estatísticas')}
						>
							<Box height={100} />
						</Panel>
					</RegularPanel>
				</DialogContent>
				{renderFooter()}
			</Dialog>
		</div>
	);
};

export default ClientForm;
