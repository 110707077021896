import React, { useState, useEffect } from 'react';
import {
	Autocomplete,
	CircularProgress,
	TextField,
	Typography,
} from '@mui/material';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import { makeStyles } from '@mui/styles';
import { fetchAvaliableCategories } from '../../services/categories';
import { usePO } from '../../utils/POContext';
import { t } from 'i18next';

interface Category {
	id: number;
	name: string;
	parent: number | null;
	children?: Category[];
}

const CategorySelector = ({ onCategorySelect, selectedCategory }) => {
	const { selectedTheme, selectedClient } = usePO();

	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [treeCategoriesData, setTreeCategoriesData] = useState<Category[]>([]);
	const [flatCategories, setFlatCategories] = useState<Category[]>([]);

	const useStyles = makeStyles({
		root: {
			'& .MuiTreeItem-content.Mui-focused': {
				backgroundColor: '#f5f5f5',
			},
			'& .MuiTreeItem-content.selected': {
				backgroundColor: '#e0f7fa',
			},
		},
		paper: {
			background: selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
		},
		option: {
			backgroundColor:
				selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
		},
		autoCompleteStyle: {
			background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
		},
	});

	const classes = useStyles();

	const categoriesFilters = {
		orderField: 'id',
		ordination: 'ASC',
		clientID: selectedClient.id,
		itemsPerPage: 9999,
	};

	useEffect(() => {
		if (selectedCategory && open) {
			setOpen(false);
		}
	}, [selectedCategory]);

	useEffect(() => {
		const fetchCategories = async () => {
			setLoading(true);
			try {
				const response = await fetchAvaliableCategories(categoriesFilters);
				const categories: any = response?.data.hits || [];
				setFlatCategories(categories);
				setTreeCategoriesData(buildTree(categories));
			} catch (error) {
				console.error('Erro ao buscar categorias:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchCategories();
	}, []);

	const filterCategories = (inputValue: string): Category[] => {
		return flatCategories.filter((category) =>
			category.name.toLowerCase().includes(inputValue.toLowerCase())
		);
	};

	const buildTree = (categories: Category[]): Category[] => {
		const categoryMap: Record<number, Category> = {};
		const tree: Category[] = [];

		categories.forEach((category) => {
			categoryMap[category.id] = { ...category, children: [] };
		});

		categories.forEach((category) => {
			if (category.parent !== null && category.parent in categoryMap) {
				categoryMap[category.parent].children?.push(categoryMap[category.id]);
			} else {
				tree.push(categoryMap[category.id]);
			}
		});

		return tree;
	};

	const renderTree = (node: Category, child?: boolean): React.JSX.Element => {
		return (
			<TreeItem
				key={node.id}
				itemId={node.id.toString()}
				sx={{
					width: '100%',
					'& .MuiTreeItem-content': {
						backgroundColor:
							selectedCategory?.id === node.id && child
								? 'rgba(0, 0, 0, 0.12)'
								: 'unset',
					},
				}}
				label={
					<Typography
						variant="body2"
						sx={{
							width: '100%',
						}}
						onClick={(event) => {
							event.stopPropagation();
							handleCategoryChange(null, node);
						}}
					>
						{node.name}
					</Typography>
				}
				classes={{
					root: classes.root,
				}}
			>
				{Array.isArray(node.children) &&
					node.children.map((child) => renderTree(child, true))}
			</TreeItem>
		);
	};

	const handleCategoryChange = (
		event: React.SyntheticEvent | null,
		newValue: Category | null
	) => {
		onCategorySelect(newValue);
	};

	return (
		<Autocomplete
			open={open}
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}
			options={treeCategoriesData}
			filterOptions={(options, { inputValue }) => {
				const filtered = filterCategories(inputValue);
				return buildTree(filtered);
			}}
			getOptionLabel={(option) => option.name}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			value={selectedCategory || null}
			onChange={handleCategoryChange}
			noOptionsText={
				loading ? (
					<CircularProgress size={20} />
				) : (
					<Typography variant="body2" color="textSecondary">
						{t('Categorias.Nenhuma categoria disponível.')}
					</Typography>
				)
			}
			loading={loading}
			classes={{
				option: classes.option,
				root: classes.autoCompleteStyle,
				paper: classes.autoCompleteStyle,
			}}
			ListboxProps={{ style: { maxHeight: 250 } }}
			sx={{
				width: '100%',
				cursor: 'pointer',
				color: selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
				'& .MuiInputBase-root': {
					'& > fieldset': {
						borderColor: selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
					},
					':hover': {
						'& > fieldset': {
							borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
						},
					},
				},
				'.MuiSvgIcon-root ': {
					fill:
						selectedTheme.id === 'dark' ? selectedTheme?.textColorMedium : '',
				},
				background:
					selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					placeholder={t('Categorias.Selecione uma categoria')}
					variant="outlined"
					size="small"
					style={{
						background:
							selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
						color:
							selectedTheme.id === 'dark' ? selectedTheme?.textColorMedium : '',
					}}
					inputProps={{
						...params.inputProps,
						style: {
							color:
								selectedTheme.id === 'dark'
									? selectedTheme?.textColorMedium
									: '',
						},
					}}
				/>
			)}
			renderOption={(props, option) => (
				<li {...props}>
					<SimpleTreeView
						sx={{ width: '100%' }}
						defaultExpandedItems={treeCategoriesData.map((node) =>
							node.id.toString()
						)}
					>
						{renderTree(option)}
					</SimpleTreeView>
				</li>
			)}
			fullWidth
		/>
	);
};

export default CategorySelector;
