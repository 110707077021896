import React, { useEffect, useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogActions,
	IconButton,
	Typography,
	Zoom,
	TextField,
	Button,
	Autocomplete,
} from '@mui/material';
import { AddCircleRounded, CloseRounded } from '@mui/icons-material';
import { t } from 'i18next';
import { Column, Line } from '../../../styles';
import { usePO } from '../../../utils/POContext';
import { OfferToolTip } from '../../../helpers';
import LoadingDots from '../../../components/LoadingDots';
import { makeStyles } from '@mui/styles';
import {
	createCategory,
	fetchAvaliableCategories,
} from '../../../services/categories';
import { enqueueSnackbar } from 'notistack';
import * as Yup from 'yup';
import FormErrorIcon from '../../../components/FormErrorIcon';

import { CategoryType } from '../../../interfaces/Categories';

interface Props {
	refresh: () => void;
}

const AddCategoriesModal: React.FC<Props> = ({ refresh }: Props) => {
	const { selectedTheme, selectedClient } = usePO();
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [formErrors, setFormErrors] = useState<any>({});
	const [filteredCategories, setFilteredCategories] = useState<CategoryType[]>(
		[]
	);
	const [selectedCategory, setSelectedCategory] = useState<CategoryType | null>(
		null
	);

	const fetchAllCategories = async () => {
		setLoading(true);

		const response = await fetchAvaliableCategories({
			orderField: 'id',
			ordination: 'ASC',
			clientID: selectedClient.id,
			itemsPerPage: 99999,
		});

		if (response?.success === true) {
			setFilteredCategories(response.data.hits);
		} else {
			setFilteredCategories([]);
			enqueueSnackbar(
				t(`Categorias.${response?.message || 'Erro desconhecido'}`),
				{ variant: 'error' }
			);
		}

		setLoading(false);
	};

	useEffect(() => {
		fetchAllCategories();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedClient]);

	const handleClose = () => {
		setDescription('');
		setName('');
		setSelectedCategory(null);
		setFormErrors({});
		setOpen(false);
	};

	const handleBorderButton = () => {
		switch (selectedTheme.id) {
			case 'dark':
				return `1px solid ${selectedTheme.footerLine}`;
			default:
				return '';
		}
	};

	const texticonStyleHeader = () => {
		switch (selectedTheme.id) {
			case 'main':
				return selectedTheme.primary;
			case 'dark':
				return selectedTheme.textColorHigh;
			default:
				return selectedTheme.foreground;
		}
	};

	const useStyles = makeStyles(() => ({
		autoCompleteStyle: {
			backgroundColor:
				selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
		},
		noOptions: {
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
		},
	}));

	const classes = useStyles();

	const handleCreateCategory = async (formData) => {
		setLoading(true);
		try {
			const response = await createCategory(formData);
			if (response?.success) {
				enqueueSnackbar(t(`Categorias.${response.message}`), {
					variant: 'success',
				});
				handleClose();
			} else {
				enqueueSnackbar(t(`Categorias.${response?.message}`), {
					variant: 'error',
				});
			}
		} catch (error: any) {
			enqueueSnackbar(t(`Categorias.${error?.message}`), {
				variant: 'error',
			});
		}
		setLoading(false);
		refresh();
	};

	async function handleSubmit() {
		try {
			const formData = {
				name: name,
				parent: selectedCategory?.id,
				ISactive: true,
				clientID: selectedClient?.id,
				description: description,
			};

			const schema = Yup.object().shape({
				name: Yup.string()
					.min(
						2,
						t(
							'Categorias.O nome da categoria precisa ter no mínimo 2 caracteres'
						)
					)
					.required(t('Categorias.O nome da categoria é obrigatório')),
				parent: Yup.string().notRequired(),
				description: Yup.string().notRequired(),
			});
			await schema.validate(formData, { abortEarly: false });
			setFormErrors({});
			handleCreateCategory(formData);
		} catch (err) {
			const validationErrors = {};
			if (err instanceof Yup.ValidationError) {
				err.inner.forEach((error: any) => {
					validationErrors[error.path] = error.message;
				});
				setFormErrors(validationErrors);
			}
		}
	}

	const renderHeader = () => (
		<DialogTitle
			style={{
				height: 60,
				background:
					selectedTheme.id === 'dark'
						? selectedTheme?.tableHead
						: selectedTheme.gradient,
				color: '#FFFFFF',
				display: 'flex',
			}}
			id="max-width-dialog-title"
		>
			<Line style={{ justifyContent: 'space-between' }}>
				<Typography
					noWrap
					style={{
						color:
							selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
						fontSize: 20,
						fontWeight: 'bold',
						maxWidth: '90%',
					}}
				>
					{t('Categorias.Adicionar Categoria')}
				</Typography>
				<IconButton onClick={handleClose} style={{ marginRight: '-16px' }}>
					<CloseRounded
						style={{
							color:
								selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
						}}
					/>
				</IconButton>
			</Line>
		</DialogTitle>
	);

	const renderCustomHeaderComponent = () => (
		<OfferToolTip
			title={t('Categorias.Adicionar Categoria')}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				style={{ marginRight: '-15px', borderRadius: 5 }}
				onClick={() => setOpen(true)}
			>
				<Typography
					variant="subtitle1"
					style={{
						fontWeight: 'bold',
						color: texticonStyleHeader(),
						marginRight: 15,
						marginTop: 2,
					}}
				>
					{t('Categorias.Adicionar Categoria')}
				</Typography>
				<AddCircleRounded
					style={{
						color: texticonStyleHeader(),
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);

	const renderContent = () => (
		<DialogActions
			style={{
				padding: '30px',
				background: selectedTheme.id === 'dark' ? selectedTheme.overlay8dp : '',
			}}
		>
			<Column
				style={{
					height: '100%',
					alignItems: 'start',
					rowGap: 10,
					background:
						selectedTheme.id === 'dark' ? selectedTheme.overlay8dp : '',
				}}
			>
				<Line style={{ justifyContent: 'space-between' }}>
					<Typography
						style={{
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						}}
					>
						{t('Categorias.Nome')}:
					</Typography>
					<FormErrorIcon formErrorMessage={formErrors?.name} />
				</Line>

				<Line
					style={{
						overflow: 'hidden',
						alignItems: 'start',
					}}
				>
					<TextField
						id="name"
						variant="outlined"
						name="name"
						value={name}
						onChange={(e) => setName(e.target.value)}
						fullWidth
						margin="dense"
						size="small"
						error={!!formErrors.name}
						InputLabelProps={{
							shrink: true,
							style: {
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							},
						}}
						sx={{
							width: '100%',
							'.MuiFormHelperText-root.Mui-error': {
								mt: '0px',
								color: selectedTheme.error,
								borderColor: selectedTheme.error,
							},
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
						}}
						inputProps={{
							style: {
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: '',
							},
						}}
					/>
				</Line>
				<Typography
					style={{
						color: selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
					}}
				>
					{t('Categorias.Vincular categoria')}:
				</Typography>
				<Line
					style={{
						overflow: 'hidden',
						alignItems: 'start',
					}}
				>
					<Autocomplete
						id="category-select"
						noOptionsText={t('Categorias.Nenhuma opção disponível')}
						options={filteredCategories}
						getOptionLabel={(option) => option.name || ''}
						isOptionEqualToValue={(option, value) => option.id === value.id}
						onChange={(_, newValue) => {
							setSelectedCategory(newValue);
						}}
						value={selectedCategory}
						renderOption={(props, option: CategoryType) => (
							<li {...props}>{option.name}</li>
						)}
						loading={loading}
						classes={{
							option: classes.autoCompleteStyle,
							paper: classes.autoCompleteStyle,
							noOptions: classes.noOptions,
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								size="small"
								placeholder={t('Categorias.Pesquisar categoria')}
								inputProps={{
									...params.inputProps,
									style: {
										color:
											selectedTheme.id === 'dark'
												? selectedTheme?.textColorHigh
												: '',
									},
								}}
								sx={{
									'& .MuiAutocomplete-paper': {
										maxHeight: '300px',
										overflowY: 'auto',
									},

									'& .MuiInputBase-root': {
										background:
											selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
										},
										':hover': {
											'& > fieldset': {
												borderColor:
													selectedTheme.id === 'dark' ? '#fff' : '#000',
											},
										},
									},
									'.MuiSvgIcon-root ': {
										fill:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorMedium,
									},
								}}
							/>
						)}
						fullWidth
					/>
				</Line>
				<Typography
					style={{
						color: selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
					}}
				>
					{t('Categorias.Descrição')}:
				</Typography>
				<TextField
					variant="outlined"
					name="description"
					value={description}
					onChange={(event) => {
						setDescription(event.target.value);
					}}
					fullWidth
					multiline
					rows={6}
					margin="dense"
					size="small"
					InputLabelProps={{
						shrink: true,
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						},
					}}
					sx={{
						width: '100%',
						'& .MuiInputBase-root': {
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
						background:
							selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
					}}
					inputProps={{
						style: {
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
						},
					}}
				/>
			</Column>
		</DialogActions>
	);

	const renderFooter = () => (
		<DialogActions
			style={{
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
				padding: '10px 15px',
				background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
			}}
		>
			<Line style={{ justifyContent: 'space-between' }}>
				<Button
					onClick={handleClose}
					disabled={loading}
					style={{ color: loading ? 'gray' : selectedTheme.error }}
				>
					{t('Categorias.Cancelar')}
				</Button>
				{loading ? (
					<LoadingDots height={30} width={70} loop />
				) : (
					<Button
						type="button"
						size="small"
						onClick={handleSubmit}
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.foreground,
							background: selectedTheme.primaryDark,
							border: handleBorderButton(),
						}}
					>
						{t('Categorias.Salvar')}
					</Button>
				)}
			</Line>
		</DialogActions>
	);

	return (
		<>
			{renderCustomHeaderComponent()}
			<Dialog
				open={open}
				onClose={handleClose}
				aria-label="confirm-edit-dialog"
				fullWidth
				maxWidth="md"
				scroll="body"
			>
				{renderHeader()}
				{renderContent()}
				{renderFooter()}
			</Dialog>
		</>
	);
};

export default AddCategoriesModal;
