import React from 'react';
import { Popover } from '@mui/material';
import { Column } from '../../../styles';
import { TreeItem, SimpleTreeView } from '@mui/x-tree-view';
import { usePO } from '../../../utils/POContext';

interface RouteItem {
	id: number;
	name: string;
}

const CategoriesPopover = ({
	anchorEl,
	open,
	onClose,
	routeData = { route: [] as RouteItem[] },
}) => {
	const { selectedTheme } = usePO();

	return (
		<Popover
			anchorEl={anchorEl}
			open={open}
			onClose={onClose}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			slotProps={{
				paper: {
					style: {
						padding: '10px',
						boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
						borderRadius: '4px',
						backgroundColor:
							selectedTheme.id === 'dark'
								? selectedTheme.overlay4dp
								: selectedTheme.foreground,
					},
				},
			}}
		>
			<Column>
				<SimpleTreeView
					defaultExpandedItems={
						routeData.route.length > 0 ? [routeData.route[0].id.toString()] : []
					}
					disableSelection
					sx={{
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					}}
				>
					{Array.isArray(routeData.route) && routeData.route.length > 0 && (
						<TreeItem
							itemId={routeData.route[0].id.toString()}
							label={routeData.route[0].name}
						>
							{routeData.route.slice(1).map((child) => (
								<TreeItem
									key={child.id}
									itemId={child.id.toString()}
									label={child.name}
									sx={{
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorHigh
												: '',
									}}
								/>
							))}
						</TreeItem>
					)}
				</SimpleTreeView>
			</Column>
		</Popover>
	);
};

export default CategoriesPopover;
