import React, { useEffect, useState } from 'react';
import { IconButton, Typography, Zoom } from '@mui/material';
import {
	SyncRounded,
	PlaylistAddRounded,
	FileCopyOutlined,
} from '@mui/icons-material/';
import { api, setupRequestToken } from '../../../../utils/api';
import { changePanelState } from '../../../../redux/slices/panelControl';
import { Line } from '../../../../styles';
import { OfferToolTip } from '../../../../helpers';
import { useAuth0 } from '../../../../react-auth0-spa';
import { useDispatch, useSelector } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';
import { usePO } from '../../../../utils/POContext';
import { useTranslation } from 'react-i18next';
import CheckProcessingItems from '../../components/CheckProcessingItems';
import DeleteDenouncementDialog from '../../components/DeleteDenouncementDialog';
import MarkAsRemovedDialog from '../ProcessItemsDialog';
import OfferTable from '../../../../components/OfferTable';
import OpenExternalLink from '../../components/OpenExternalLink';
import SelectClient from '../../../../components/SelectClient';
import ShortCutModal from '../../../../components/ShortCutModal';
import { enqueueSnackbar } from 'notistack';

interface Props {
	data: any;
	error: boolean;
	loading: boolean;
	reloadTable?: () => void;
}

const ApplyDenouncementTable: React.FC<Props> = ({
	data,
	error,
	loading,
	reloadTable,
}: Props) => {
	const { t } = useTranslation();
	const { selectedClient, selectedTheme } = usePO();
	const { token } = useAuth0();
	setupRequestToken(api, token);

	useHotkeys('ctrl+alt+f', () => handleKeyShortcutFilter());

	const [tableData, setTableData] = useState<any[]>([]);
	const [selectedTableItems, setSelectedTableItems] = useState<any[]>([]);

	const dispatch = useDispatch();
	const panelControlList = useSelector((state: any) => state.panelControl);

	const handlePanelControlState = () => {
		panelControlList.panelControl === 'notifiedPanelControl'
			? dispatch(changePanelState(''))
			: dispatch(changePanelState('notifiedPanelControl'));
	};

	useEffect(() => {
		dispatch(changePanelState(''));
	}, [dispatch]);

	const colsData = [
		{
			columnTitle: t('Notificações.Tabs.Notificadas.Anúncio'),
			fieldName: 'title',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 300,
		},
		{
			columnTitle: t('Notificações.Tabs.Notificadas.Plataforma'),
			fieldName: 'platform',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 150,
		},
		{
			columnTitle: t('Notificações.Tabs.Notificadas.Denunciante'),
			fieldName: 'denouncerName',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 150,
		},
		{
			columnTitle: t('Notificações.Tabs.Notificadas.Motivo da Classificação'),
			fieldName: 'classificationType',
			translateColumnData: true,
			alignTitle: 'center',
			cellWidth: 150,
		},
		{
			columnTitle: t('Notificações.Tabs.Notificadas.Data'),
			fieldName: 'created',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 100,
			dataType: 'date',
		},
	];

	const ShortCutsData = [
		{
			description: t(
				'Notificações.Tabs.Notificadas.Selecionar todos as denúncias da página atual'
			),
			shortcut: ['Ctrl', 'Alt', 'A'],
		},
		{
			description: t(
				'Notificações.Tabs.Notificadas.Abrir/fechar barra de filtros avançados'
			),
			shortcut: ['Ctrl', 'Alt', 'F'],
		},
		{
			description: t(
				'Notificações.Tabs.Notificadas.Processar denúncias selecionadas'
			),
			shortcut: ['Ctrl', 'Alt', 'Enter'],
		},
		{
			description: `${t('Notificações.Tabs.Notificadas.Opção')} 1 - ${t(
				'Notificações.Tabs.Notificadas.Já Removido da Plataforma'
			)}`,
			shortcut: ['Ctrl', 'Alt', '1'],
		},
		{
			description: `${t('Notificações.Tabs.Notificadas.Opção')} 2 - ${t(
				'Notificações.Tabs.Notificadas.Enviar para fila de Verificação'
			)}`,
			shortcut: ['Ctrl', 'Alt', '2'],
		},
	];

	const copyToClipboard = async () => {
		const allLinks = selectedTableItems
			.map((el) => el.link)
			.join(', \n')
			.toString();
		navigator.clipboard.writeText(allLinks);
		const itensCount = selectedTableItems.length;
		const message =
			itensCount === 1
				? `${itensCount} ${t('Item copiado com sucesso')}`
				: `${itensCount} ${t('Itens copiados com sucesso')}`;

		enqueueSnackbar(message, {
			variant: 'success',
		});
	};

	const buildTableData = (rowData: any) => {
		setTableData([]);
		rowData.map((el) => {
			const formatTableData = {
				title: el.offer.title,
				id: el.offerClassification.offerClassificationID,
				created: el.created,
				denouncerName: `${el.denouncer.denouncerName} (${el.denouncer.denouncerEmail})`,
				observation: el.offerClassification.observation,
				classificationType: t(
					'ClassificationType.' + el.classificationType.classificationTypeID
				),
				classificationTypeID: el.classificationType.classificationTypeID,
				platform: el.offer.platformINcountryID.replace('|', ' - '),
				link: el.offer.permalink,
				offerClassificationID: el.offerClassification.offerClassificationID,
				platformID: el.offer.platformINcountryID,
			};
			setTableData((dataINtable) => [...dataINtable, formatTableData]);
			return null;
		});
	};

	useEffect(() => {
		buildTableData(data);
	}, [data]);

	if (tableData.length === 0 && data.length !== 0) {
		buildTableData(data);
	}

	const action2Props = { reloadTable: reloadTable };

	const handleKeyShortcutFilter = () => {
		handlePanelControlState();
	};

	const leftFooterComponent = () => (
		<>
			<OfferToolTip
				title={t('Notificações.Atualizar dados da tabela')}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<IconButton
					onClick={reloadTable}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<SyncRounded
						style={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							fontSize: 20,
						}}
					/>
				</IconButton>
			</OfferToolTip>
			{selectedTableItems.length !== 0 ? (
				<OfferToolTip
					title={`${t('Notificações.Tabs.Classificadas.Copiar para a área de transferência')}`}
					aria-label="inativo"
					enterDelay={700}
					enterNextDelay={700}
					arrow
					TransitionComponent={Zoom}
				>
					<IconButton
						disabled={selectedTableItems.length === 0 ? true : false}
						onClick={() => copyToClipboard()}
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<FileCopyOutlined
							style={{
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
								fontSize: 20,
							}}
						/>
					</IconButton>
				</OfferToolTip>
			) : null}
			<OfferToolTip
				title={
					panelControlList.panelControl === 'classifiedPanelControl'
						? t('Ocultar barra de filtros')
						: t('Mostrar barra de filtros')
				}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<IconButton
					onClick={handlePanelControlState}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<PlaylistAddRounded
						style={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					/>
				</IconButton>
			</OfferToolTip>
		</>
	);

	const rightFooterComponent = () => (
		<Line style={{ marginLeft: 20, justifyContent: 'flex-end' }}>
			<Typography
				sx={{
					color: selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
				}}
			>
				{t('Notificações.Tabs.Notificadas.Cliente')}:
			</Typography>
			<div style={{ width: 250, marginBottom: -5 }}>
				<SelectClient />
			</div>
		</Line>
	);

	const renderFooterButton = () => (
		<MarkAsRemovedDialog
			setTableData={setTableData}
			data={selectedTableItems}
			setData={setSelectedTableItems}
			reloadTable={reloadTable}
		/>
	);

	const renderMessages = () =>
		// eslint-disable-next-line no-nested-ternary
		error
			? t('Erro ao receber dados')
			: loading
				? t('Carregando...')
				: t('Notificações.Tabs.Notificadas.Sem denúncias para este cliente');

	return (
		<>
			<OfferTable
				colsData={colsData}
				tableData={tableData}
				tableActions
				tableFooter
				selectAll
				selectedTableItems={selectedTableItems}
				setSelectedTableItems={setSelectedTableItems}
				checkboxes
				ternaryCheckbox
				customNoDataMessage={
					selectedClient
						? renderMessages()
						: t('Notificações.Tabs.Notificadas.Selecione um cliente')
				}
				customLeftFooterComponent={leftFooterComponent()}
				customRightFooterComponent={rightFooterComponent()}
				customInfoButton={
					<ShortCutModal
						headerTitle={t('Notificações.Tabs.Notificadas.Aba de Notificadas')}
						data={ShortCutsData}
					/>
				}
				ActionComponent1={OpenExternalLink}
				ActionComponent2={DeleteDenouncementDialog}
				ActionComponent2Props={action2Props}
				tableFooterButton={renderFooterButton()}
			/>
			<CheckProcessingItems tableData={data} />
		</>
	);
};

export default ApplyDenouncementTable;
