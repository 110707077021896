import React, { useEffect, useState } from 'react';
import {
	Checkbox,
	Chip,
	FormControlLabel,
	TextField,
	Typography,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { Column, Line } from '../../../../styles';
import { usePO } from '../../../../utils/POContext';
import SearchTermsInput from '../../../../components/OfferMultiInput';
import { enqueueSnackbar } from 'notistack';
import { CloseRounded } from '@mui/icons-material';
import PlatformSelector from '../PlatformSelector';
import FormErrorIcon from '../../../../components/FormErrorIcon';

interface Props {
	buildingSearch?: boolean;
	excludingTermsToSearch: any;
	formErrors?: any;
	readOnly?: boolean;
	searchData?: any;
	searchTitle?: string;
	selectedDomain: boolean;
	setSelectedDomain: (data: boolean) => void;
	selectedTypos: boolean;
	setSelectedTypos: (data: boolean) => void;
	selectedPlatforms: any;
	setDescription?: (data: string) => void;
	description?: string;
	setExcludingTermsToSearch: (data) => void;
	setSearchTitle?: (data: string) => void;
	setSelectedPlatforms: (data) => void;
	setTearmsToSearch: (data) => void;
	termsToSearch: any;
	minPrice?: any;
	setMinPrice?: (data: string) => void;
	maxPrice?: any;
	setMaxPrice?: (data: string) => void;
	category?: any;
	setCategory?: (data: string) => void;
	step?: any;
	setStep?: (data: string) => void;
	getSolds: boolean;
	setGetSolds: (data: boolean) => void;
	isEditing?: boolean;
}

const LeftColumn: React.FC<Props> = ({
	readOnly,
	searchTitle,
	setSearchTitle,
	selectedDomain,
	setSelectedDomain,
	selectedTypos,
	setSelectedTypos,
	setTearmsToSearch,
	termsToSearch,
	excludingTermsToSearch,
	setExcludingTermsToSearch,
	buildingSearch,
	formErrors,
	selectedPlatforms,
	setSelectedPlatforms,
	minPrice,
	setMinPrice,
	maxPrice,
	setMaxPrice,
	category,
	setCategory,
	step,
	setStep,
	getSolds,
	setGetSolds,
	isEditing,
}: Props) => {
	const { selectedTheme } = usePO();
	const { t } = useTranslation();

	const [, setValueErrors] = useState(false);

	useEffect(() => {
		if (selectedDomain) {
			setSelectedPlatforms([
				{
					id: 'WHOISXML',
					inCountryID: 'WHOISXML|BRA',
					countryID: 'BRA',
					name: 'WhoisXML',
					country: 'Brasil',
				},
			]);
		} else {
			setSelectedPlatforms((prevPlatforms) =>
				prevPlatforms.filter((p) => p.inCountryID !== 'WHOISXML|BRA')
			);
			setSelectedTypos(false);
		}
	}, [selectedDomain]);

	const renderPlatformSelector = () => {
		if (buildingSearch && !selectedDomain) {
			return (
				<PlatformSelector
					setSelectedPlatforms={setSelectedPlatforms}
					selectedPlatforms={selectedPlatforms}
				/>
			);
		}
		return null;
	};

	const handleDeletePlatform = (deleteItem) => {
		if (buildingSearch && (!selectedDomain || deleteItem !== 'WHOISXML|BRA')) {
			setSelectedPlatforms((platforms) =>
				platforms.filter((alreadySelected) => alreadySelected.id !== deleteItem)
			);
		}
	};

	const formatCurrency = (value) => {
		const numericValue = value.replace(/\D/g, '');
		if (numericValue === '') return '0,00';
		const intValue = parseInt(numericValue, 10) / 100;

		return intValue.toLocaleString('pt-BR', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});
	};

	const handlePriceChange = (e, setPrice) => {
		const { value } = e.target;
		setPrice(formatCurrency(value));
	};

	const handleStepChange = (e, setStep) => {
		const { value } = e.target;
		setStep(formatCurrency(value));
	};

	const checkValueErrors = () => {
		const min = parseFloat(minPrice.replace(/\./g, '').replace(',', '.'));
		const max = parseFloat(maxPrice.replace(/\./g, '').replace(',', '.'));

		if (min > max) {
			setValueErrors(true);
			enqueueSnackbar(t('Valores incompatíveis'), { variant: 'warning' });
		} else {
			setValueErrors(false);
		}
	};

	const handleEnterKey = (event) => {
		if (event.key === 'Enter') {
			checkValueErrors();
		}
	};

	const renderSearchTermsInput = () => {
		if (buildingSearch) {
			return (
				<SearchTermsInput
					setDataArray={setTearmsToSearch}
					dataArray={termsToSearch}
					maxItems={1}
					noAutoComplete
					size="small"
					fieldName="terms"
					formErrors={formErrors?.terms}
					colorChip={selectedTheme.primaryDark}
				/>
			);
		}
		return (
			<Line
				style={{
					width: '100%',
					flexWrap: 'wrap',
					alignItems: 'start',
					borderStyle: 'solid',
					borderWidth: 1,
					borderColor: formErrors?.terms
						? 'red'
						: selectedTheme.tone === 'dark'
							? '#707070'
							: '#cacaca',
					borderRadius: 4,
					padding: 4,
					background:
						selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
				}}
			>
				{termsToSearch.map((el) => (
					<Chip
						key={el}
						variant="outlined"
						label={el}
						style={{
							height: '30px',
							margin: 2,
							backgroundColor: selectedTheme.primaryDark,
							borderColor: selectedTheme.id === 'dark' ? '' : '#eaeaea',
							borderRadius: '4px',
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorMedium
									: selectedTheme?.foreground,
							fontSize: 15,
						}}
					/>
				))}
			</Line>
		);
	};

	const renderSearchExcludingTermsInput = () => {
		return (
			<SearchTermsInput
				setDataArray={setExcludingTermsToSearch}
				dataArray={excludingTermsToSearch}
				maxItems={99}
				noAutoComplete
				size="small"
				fieldName="excludingTerms"
				formErrors={formErrors?.excludingTerms}
				colorChip={selectedTheme.primaryLight}
			/>
		);
	};

	return (
		<Column
			style={{
				height: '100%',
				alignItems: 'start',
				justifyContent: 'space-between',
				color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
			}}
		>
			<Line style={{ justifyContent: 'space-between' }}>
				<Typography
					style={{
						marginBottom: 14,
					}}
				>
					{t('Buscas.Tabs.Nova Busca.Título da Busca')}:
				</Typography>
				<FormErrorIcon formErrorMessage={formErrors?.title} />
			</Line>
			<Line
				style={{
					overflow: 'hidden',
					alignItems: 'start',
				}}
			>
				<TextField
					aria-label="title"
					variant="outlined"
					size="small"
					name="title"
					value={searchTitle}
					error={!!formErrors?.title}
					autoComplete="off"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						setSearchTitle?.(event.target.value);
					}}
					style={{ marginRight: 20 }}
					InputProps={{
						readOnly,
						style: {
							color:
								selectedTheme.id === 'dark'
									? selectedTheme?.textColorMedium
									: '',
						},
					}}
					sx={{
						width: '100%',
						'.MuiFormHelperText-root.Mui-error': {
							mt: '0px',
							color: selectedTheme.error,
							borderColor: selectedTheme.error,
						},
						'& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
							{
								borderColor: selectedTheme.error,
							},
						'& .MuiInputBase-root': {
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							height: 45,

							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
					}}
				/>
				<FormControlLabel
					style={{ marginRight: 0, gap: 5 }}
					control={
						<Checkbox
							color="primary"
							checked={selectedDomain}
							disabled={isEditing}
							onChange={(event) => setSelectedDomain(event.target.checked)}
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.primaryDark,
							}}
						/>
					}
					label={
						<Typography
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.primary,
								whiteSpace: 'nowrap',
							}}
						>
							{t('Buscas.Tabs.Nova Busca.Domínio')}
						</Typography>
					}
				/>
			</Line>

			<Line
				style={{
					display: 'flex',
					alignItems: 'flex-end',
					marginTop: 32,
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						gap: 14,
						flexGrow: 1,
					}}
				>
					<Line style={{ justifyContent: 'space-between' }}>
						<Typography>
							{t('Buscas.Tabs.Nova Busca.Termos de Busca')}:
						</Typography>
						<FormErrorIcon formErrorMessage={formErrors?.terms} />
					</Line>
					<Line
						style={{
							overflow: 'hidden',
							alignItems: 'start',
							color:
								selectedTheme.id === 'dark' ? selectedTheme.primaryDark : '',
							gap: '20px',
						}}
					>
						{renderSearchTermsInput()}
						{selectedDomain && (
							<FormControlLabel
								style={{ marginRight: 0, gap: 5 }}
								control={
									<Checkbox
										color="primary"
										checked={selectedTypos}
										disabled={isEditing}
										onChange={(event) => setSelectedTypos(event.target.checked)}
										style={{
											color:
												selectedTheme.id === 'dark'
													? selectedTheme.textColorHigh
													: selectedTheme.primaryDark,
										}}
									/>
								}
								label={
									<Typography
										style={{
											color:
												selectedTheme.id === 'dark'
													? selectedTheme.textColorHigh
													: selectedTheme.primary,
											whiteSpace: 'nowrap',
										}}
									>
										{t('Buscas.Tabs.Nova Busca.Typos')}
									</Typography>
								}
							/>
						)}
					</Line>
				</div>
			</Line>

			{!selectedDomain && (
				<Line
					style={{
						display: 'flex',
						alignItems: 'flex-end',
						gap: '20px',
						marginTop: 32,
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
							gap: 14,
							flexGrow: 1,
						}}
					>
						<Typography>
							{t('Buscas.Tabs.Nova Busca.Termos adicionais')}:
						</Typography>
						<Line
							style={{
								height: '40px',
								color:
									selectedTheme.id === 'dark' ? selectedTheme.primaryDark : '',
							}}
						>
							<TextField
								name="Preço mínimo"
								label={t('Buscas.Tabs.Nova Busca.Preço mínimo')}
								value={minPrice}
								onChange={(e) => handlePriceChange(e, setMinPrice)}
								onKeyDown={handleEnterKey}
								data-testid="preço mínimo"
								variant="outlined"
								size="small"
								fullWidth
								disabled={isEditing}
								style={{ marginRight: 10 }}
								sx={{
									width: '100%',
									'& .MuiFormLabel-root': {
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorMedium,
									},
									'& .MuiInputBase-root': {
										height: 45,
										background:
											selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
										},
										':hover': {
											'& > fieldset': {
												borderColor:
													selectedTheme.id === 'dark' ? '#fff' : '#000',
											},
										},
									},
									'& .MuiInputBase-root.Mui-disabled': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
										},
									},
									'& .MuiInputBase-input.Mui-disabled': {
										WebkitTextFillColor:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorDisable
												: '',
									},
									'& .MuiInputLabel-root.Mui-disabled': {
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorDisable
												: '',
									},
									background:
										selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
								}}
								inputProps={{
									style: {
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme.textColorMedium,
									},
								}}
							/>
							<TextField
								name="Preço máximo"
								label={t('Buscas.Tabs.Nova Busca.Preço máximo')}
								value={maxPrice}
								onChange={(e) => handlePriceChange(e, setMaxPrice)}
								onKeyDown={handleEnterKey}
								data-testid="preço máximo"
								variant="outlined"
								size="small"
								fullWidth
								disabled={isEditing}
								style={{ marginRight: 10 }}
								sx={{
									width: '100%',
									'& .MuiFormLabel-root': {
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorMedium,
									},
									'& .MuiInputBase-root': {
										height: 45,
										background:
											selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
										},
										':hover': {
											'& > fieldset': {
												borderColor:
													selectedTheme.id === 'dark' ? '#fff' : '#000',
											},
										},
									},
									'& .MuiInputBase-root.Mui-disabled': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
										},
									},
									'& .MuiInputBase-input.Mui-disabled': {
										WebkitTextFillColor:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorDisable
												: '',
									},
									'& .MuiInputLabel-root.Mui-disabled': {
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorDisable
												: '',
									},
									background:
										selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
								}}
								inputProps={{
									style: {
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme.textColorMedium,
									},
								}}
							/>
							<TextField
								name="Categoria"
								label={t('Buscas.Tabs.Nova Busca.Categoria')}
								value={category}
								disabled={isEditing}
								onChange={(event) => setCategory?.(event.target.value)}
								data-testid="categoria"
								variant="outlined"
								size="small"
								fullWidth
								style={{ marginRight: 10 }}
								sx={{
									width: '100%',
									'& .MuiFormLabel-root': {
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorMedium,
									},
									'& .MuiInputBase-root': {
										height: 45,
										background:
											selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
										},
										':hover': {
											'& > fieldset': {
												borderColor:
													selectedTheme.id === 'dark' ? '#fff' : '#000',
											},
										},
									},
									'& .MuiInputBase-root.Mui-disabled': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
										},
									},
									'& .MuiInputBase-input.Mui-disabled': {
										WebkitTextFillColor:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorDisable
												: '',
									},
									'& .MuiInputLabel-root.Mui-disabled': {
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorDisable
												: '',
									},
									background:
										selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
								}}
								inputProps={{
									style: {
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme.textColorMedium,
									},
								}}
							/>
							<TextField
								name="Passo"
								label={t('Buscas.Tabs.Nova Busca.Passo')}
								value={step}
								disabled={isEditing}
								onChange={(e) => handleStepChange(e, setStep)}
								data-testid="passo"
								variant="outlined"
								size="small"
								fullWidth
								style={{ marginRight: 20 }}
								sx={{
									width: '100%',
									'& .MuiFormLabel-root': {
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorMedium,
									},
									'& .MuiInputBase-root': {
										height: 45,
										background:
											selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
										},
										':hover': {
											'& > fieldset': {
												borderColor:
													selectedTheme.id === 'dark' ? '#fff' : '#000',
											},
										},
									},
									'& .MuiInputBase-root.Mui-disabled': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
										},
									},
									'& .MuiInputBase-input.Mui-disabled': {
										WebkitTextFillColor:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorDisable
												: '',
									},
									'& .MuiInputLabel-root.Mui-disabled': {
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorDisable
												: '',
									},
									background:
										selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
								}}
								inputProps={{
									style: {
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme.textColorMedium,
									},
								}}
							/>
							<FormControlLabel
								style={{ marginRight: 0, gap: 5 }}
								control={
									<Checkbox
										color="primary"
										checked={getSolds}
										disabled={isEditing}
										onChange={(event) => setGetSolds(event.target.checked)}
										style={{
											color:
												selectedTheme.id === 'dark'
													? selectedTheme.textColorHigh
													: selectedTheme.primaryDark,
										}}
									/>
								}
								label={
									<Typography
										style={{
											color:
												selectedTheme.id === 'dark'
													? selectedTheme.textColorHigh
													: selectedTheme.primary,
											whiteSpace: 'nowrap',
										}}
									>
										{t('Buscas.Tabs.Nova Busca.Itens vendidos')}
									</Typography>
								}
							/>
						</Line>
					</div>
				</Line>
			)}

			<Line
				style={{
					display: 'flex',
					alignItems: 'flex-end',
					gap: '20px',
					marginTop: 32,
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						gap: 14,
						flexGrow: 1,
					}}
				>
					<Typography>
						{t('Buscas.Tabs.Nova Busca.Termos Excludentes')}:
					</Typography>
					<Line
						style={{
							overflow: 'hidden',
							alignItems: 'start',
							color:
								selectedTheme.id === 'dark' ? selectedTheme.primaryDark : '',
						}}
					>
						{renderSearchExcludingTermsInput()}
					</Line>
				</div>
			</Line>

			<Line
				style={{
					display: 'flex',
					alignItems: 'flex-end',
					marginTop: 32,
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						gap: 14,
						flexGrow: 1,
					}}
				>
					<Line style={{ justifyContent: 'space-between' }}>
						<Typography>{t('Buscas.Tabs.Nova Busca.Plataformas')}:</Typography>
						<FormErrorIcon formErrorMessage={formErrors?.platforms} />
					</Line>

					<Line style={{ display: 'flex', gap: 5 }}>
						{renderPlatformSelector()}
						<Line
							style={{
								minHeight: 45,
								width: '100%',
								flexWrap: 'wrap',
								alignItems: 'start',
								borderStyle: 'solid',
								borderWidth: 1,
								borderColor: formErrors?.platforms
									? selectedTheme.error
									: selectedTheme.tone === 'dark'
										? '#707070'
										: '#cacaca',
								borderRadius: 4,
								padding: 4,
								backgroundColor:
									selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
							}}
						>
							{selectedPlatforms?.map((el) => (
								<Chip
									key={buildingSearch ? `${el.name} - ${el.country}` : el}
									variant="outlined"
									label={buildingSearch ? `${el.name} - ${el.country}` : el}
									onDelete={() => handleDeletePlatform(el.inCountryID)}
									deleteIcon={
										<CloseRounded
											fontSize="small"
											style={{
												display: buildingSearch ? 'block' : 'none',
												color:
													selectedTheme.id === 'dark'
														? selectedTheme.textColorMedium
														: selectedTheme.foreground,
											}}
										/>
									}
									style={{
										height: '30px',
										margin: 2,
										backgroundColor:
											selectedTheme.id === 'dark'
												? selectedTheme?.primary
												: selectedTheme.primary,
										borderColor: selectedTheme.id === 'dark' ? '' : '#eaeaea',
										borderRadius: '4px',
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorMedium
												: selectedTheme.foreground,
										fontSize: 15,
									}}
								/>
							))}
						</Line>
					</Line>
				</div>
			</Line>
		</Column>
	);
};
export default LeftColumn;
