import React, { useState } from 'react';
import {
	Box,
	Card,
	Checkbox,
	Divider,
	IconButton,
	Link,
	Typography,
	Zoom,
} from '@mui/material';
import {
	SwapHorizRounded,
	OpenInNewRounded,
	WallpaperRounded,
	VerifiedUser,
	ShareRounded,
} from '@mui/icons-material/';
import './image-galery-card.css';
import ImageGallery from 'react-image-gallery';
import { useTranslation } from 'react-i18next';
import ImageNotFound from '../../../../assets/images/image_not_found.jpg';
import { Column, Line } from '../../../../styles';
import { OfferToolTip } from '../../../../helpers';
import { usePO } from '../../../../utils/POContext';

import ClassificationModal from '../../Modal';
import FullSizeImageDialog from '../../../../components/FullSizeImageModal';
import CardDenounceConfirmationDialog from '../CardDenounceConfirmationDialog';
import PlatformIcon from '../../../../components/PlatformIcon';
import { useCardSelector } from '../../../../hooks/Classificacoes/CardSelector';
import { useSelector } from 'react-redux';
import MultiItemCarousel from '../../MultiItemCarouselDisplay';
import { useSnackbar } from 'notistack';

interface Props {
	cardAsGood: boolean;
	className: any;
	data: any;
	handleFlip: () => void;
	handleRemoveCardAsGood: () => void;
	handleSetTags: (cardsTag) => void;
	thumb: any;
	index: number;
	offersDataPackage: object[];
}

const CardFront: React.FC<Props> = ({
	cardAsGood,
	className,
	data,
	handleFlip,
	handleRemoveCardAsGood,
	handleSetTags,
	thumb,
	index,
	offersDataPackage,
}) => {
	const { selectedTheme, userData, selectedClient } = usePO();
	const { enqueueSnackbar } = useSnackbar();
	const { selectedCards, handleSelectCard } = useCardSelector();
	const { t } = useTranslation();

	const [openAnalisysModal, setOpenAnalisysModal] = useState(false);
	const [openMultiOfferModal, setOpenMultiOfferModal] = useState(false);

	const [imageIndex, setImageIndex] = useState(0);
	const [openImageModal, setOpenImageModal] = useState(false);

	const goodCardsList = useSelector(
		(state: any) => state.cardFeedback.goodCardsList
	);

	const handleSelect = (event) => {
		handleSelectCard(
			{ offerID: data.offerID, tags: data.tags },
			event.target.checked ? 'add' : 'remove'
		);
	};

	const handleVerifyCheckBox = () => {
		if (data.author.authorData?.official?.value) {
			return false;
		} else if (
			goodCardsList.includes(data.offerID) ||
			data.clientsClassifiedAsGood?.includes(selectedClient?.id)
		) {
			return false;
		}
		return true;
	};

	const checkPermission = () => {
		if (userData.roles[0] === 'Denunciante') {
			return false;
		} else {
			return true;
		}
	};

	const handleCopyLinkToURL = () => {
		const { protocol, host } = window.location;
		const url = `${protocol}//${host}/offerID/${data.offerID}`;
		navigator.clipboard
			.writeText(url)
			.then(() => {
				enqueueSnackbar(
					t('Classificações.Link copiado para a área de transferência'),
					{ variant: 'success' }
				);
			})
			.catch((err) => {
				console.error('Error copying to clipboard', err);
			});
	};

	const handleLineColor = () => {
		if (data.offerRisk) {
			return selectedTheme.error;
		}
		if (
			goodCardsList.includes(data.offerID) ||
			data.author.authorData.official?.value ||
			cardAsGood
		) {
			return selectedTheme.success;
		}
		return 'transparent';
	};

	const renderCardHeader = () => (
		<Line
			style={{
				justifyContent: 'space-between',
				position: 'absolute',
				top: 0,
				padding: '3px 7px',
			}}
		>
			<IconButton
				size="small"
				onClick={handleFlip}
				data-testid="cardFlipFront"
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<SwapHorizRounded
					style={{
						zIndex: 10000,
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
					}}
				/>
			</IconButton>
			<OfferToolTip
				title={
					<Typography variant="subtitle2" align="center">{`${t(
						'Classificações.Abrir anúncio na plataforma de origem'
					)} (${data.platformINcountryID.replace('|', ' - ')})`}</Typography>
				}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<Link href={data.permalink} target="blank">
					<PlatformIcon
						data={data.platformINcountryID}
						dataType="platform"
						size={24}
						cursor="pointer"
					/>
				</Link>
			</OfferToolTip>
		</Line>
	);

	const renderCardImages = () => {
		let imagesArray: { thumbnail: string; original: string }[] = [];
		if (thumb) {
			if (typeof thumb === 'string') {
				imagesArray.push({
					thumbnail: thumb,
					original: thumb,
				});
			} else if (thumb.map) {
				imagesArray = thumb.map((el: string) => {
					return {
						thumbnail: el,
						original: el,
					};
				});
			}
		} else if (data.images && data.images.length > 0) {
			imagesArray.push({
				thumbnail: data.images[0],
				original: data.images[0],
			});
		}

		if (imagesArray.length > 0) {
			return (
				<Column
					style={{
						justifyContent: 'center',
						height: 150,
						zIndex: 1,
					}}
				>
					<div style={{ cursor: 'pointer' }}>
						<ImageGallery
							key="card"
							items={imagesArray}
							onErrorImageURL={ImageNotFound}
							disableKeyDown
							showThumbnails={false}
							showPlayButton={false}
							showFullscreenButton={false}
							onSlide={(e) => setImageIndex(e)}
							onClick={() => setOpenMultiOfferModal(true)}
						/>
					</div>
				</Column>
			);
		} else {
			return (
				<Column style={{ justifyContent: 'center', height: 150 }}>
					<WallpaperRounded fontSize="large" color="disabled" />
					<Typography
						variant="subtitle2"
						style={{ marginTop: 10, color: '#a0a0a0', fontWeight: 'bold' }}
					>
						{t('Classificações.Imagem não disponível')}
					</Typography>
				</Column>
			);
		}
	};

	const isValidCurrencyCode = (code) => {
		return /^[A-Z]{3}$/.test(code);
	};
	const renderCardMainInfo = () => {
		return (
			<Column
				onClick={() => setOpenMultiOfferModal(true)}
				style={{ padding: '5px 15px', height: 70, cursor: 'pointer' }}
				data-testid="cardModalFront"
			>
				<OfferToolTip
					title={data.title}
					aria-label="inativo"
					enterDelay={700}
					enterNextDelay={700}
					arrow
					TransitionComponent={Zoom}
				>
					<Box
						width="100%"
						overflow="hidden"
						textOverflow="ellipsis"
						whiteSpace="nowrap"
					>
						<Typography
							noWrap
							align="center"
							style={{
								fontWeight: 'bold',
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							}}
						>
							{data.title}
						</Typography>
					</Box>
				</OfferToolTip>
				{data.offerData.price?.value ? (
					<Typography
						variant="h5"
						style={{
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						}}
					>
						{parseFloat(data.offerData.price?.value).toLocaleString(
							userData.i18nID,
							{
								style: 'currency',
								currency: isValidCurrencyCode(data.offerData.currency?.value)
									? data.offerData.currency.value
									: 'BRL',
								minimumFractionDigits: 2,
							}
						) || 'preço não disponível'}
					</Typography>
				) : null}
			</Column>
		);
	};

	const renderCardDetails = () => (
		<Column
			style={{
				justifyContent: 'space-around',
				padding: '5px 15px',
				height: 60,
			}}
		>
			<Box
				width="100%"
				overflow="hidden"
				textOverflow="ellipsis"
				whiteSpace="nowrap"
			>
				<Line style={{ justifyContent: 'center' }}>
					{data.author.authorData.official?.value ? (
						<OfferToolTip
							aria-label="inativo"
							arrow
							enterDelay={700}
							enterNextDelay={700}
							title={`${t('Classificações.Loja oficial')}`}
							TransitionComponent={Zoom}
						>
							<VerifiedUser
								style={{
									marginRight: 5,
									fontSize: 16,
									color: selectedTheme.success,
								}}
							/>
						</OfferToolTip>
					) : null}
					<Link
						href={data.author.permalink}
						target="blank"
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: '#000000de',
							textDecorationColor:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: '#000000de',
						}}
					>
						<OfferToolTip
							title={`${t(
								'Classificações.Abrir página do vendedor na plataforma'
							)}`}
							aria-label="inativo"
							enterDelay={700}
							enterNextDelay={700}
							arrow
							TransitionComponent={Zoom}
						>
							<Typography
								align="center"
								noWrap
								style={{
									fontWeight: 'bold',
								}}
							>
								{data.author.authorName ||
									data.author.authorData.nickname?.value}
							</Typography>
						</OfferToolTip>
					</Link>
				</Line>
			</Box>
			<OfferToolTip
				title={data.categories?.name || null}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				TransitionComponent={Zoom}
				arrow
			>
				<Box
					width="100%"
					overflow="hidden"
					textOverflow="ellipsis"
					whiteSpace="nowrap"
				>
					<Typography
						variant="subtitle2"
						align="center"
						style={{
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						}}
					>
						{data.categories?.name || null}
					</Typography>
				</Box>
			</OfferToolTip>
		</Column>
	);

	const renderCardFooter = () => (
		<Line
			style={{
				justifyContent: 'space-between',
				padding: '4px 10px 4px 8px',
			}}
		>
			<Line style={{ justifyContent: 'flex-start' }}>
				{checkPermission() && handleVerifyCheckBox() ? (
					<Checkbox
						size="small"
						checked={
							selectedCards.find((card) => card.offerID === data.offerID)
								? true
								: false
						}
						onClick={handleSelect}
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primaryDark,
						}}
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					/>
				) : null}
				<IconButton
					onClick={handleCopyLinkToURL}
					sx={{
						padding: '6px',
						marginLeft: '-5px',
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<ShareRounded
						style={{
							fontSize: 20,
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primaryDark,
						}}
					/>
				</IconButton>

				<OfferToolTip
					title={`${t('Classificações.Abrir tela de classificação')}`}
					aria-label="inativo"
					enterDelay={700}
					enterNextDelay={700}
					arrow
					TransitionComponent={Zoom}
				>
					<IconButton
						onClick={() => setOpenMultiOfferModal(true)}
						sx={{
							padding: '6px',
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<OpenInNewRounded
							style={{
								fontSize: 20,
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.primaryDark,
							}}
						/>
					</IconButton>
				</OfferToolTip>
			</Line>
			{checkPermission() ? (
				<Line style={{ justifyContent: 'flex-end' }}>
					<CardDenounceConfirmationDialog
						offerData={data}
						cardAsGood={cardAsGood}
						handleRemoveCardAsGood={handleRemoveCardAsGood}
					/>
				</Line>
			) : null}
		</Line>
	);

	const renderCardLeftLine = () => (
		<Column
			style={{
				width: 3,
				height: 330,
				background: handleLineColor(),
				opacity: 0.7,
			}}
		/>
	);

	return (
		<>
			<Card
				className={className}
				style={{
					width: 250,
					height: 330,
					backgroundColor:
						selectedTheme.id === 'dark' && selectedTheme.overlay4dp,
				}}
			>
				<Line>
					{renderCardLeftLine()}
					<div style={{ width: 247, marginLeft: -2 }}>
						{renderCardHeader()}
						{renderCardImages()}

						<Divider
							sx={{ opacity: 0.6 }}
							style={{
								margin: '0px 15px',
								background:
									selectedTheme.id === 'dark' && selectedTheme.footerLine,
							}}
						/>
						{renderCardMainInfo()}
						<Divider
							sx={{ opacity: 0.6 }}
							style={{
								margin: '0px 15px',
								background:
									selectedTheme.id === 'dark' && selectedTheme.footerLine,
							}}
						/>
						{renderCardDetails()}
						<Divider
							sx={{ opacity: 0.6 }}
							style={{
								margin: '0px 15px',
								background:
									selectedTheme.id === 'dark' && selectedTheme.footerLine,
							}}
						/>
						{renderCardFooter()}
					</div>
				</Line>
			</Card>
			<MultiItemCarousel
				index={index}
				offersDataPackage={offersDataPackage}
				open={openMultiOfferModal}
				setOpen={setOpenMultiOfferModal}
			/>
			<ClassificationModal
				open={openAnalisysModal}
				setOpen={setOpenAnalisysModal}
				offerData={data}
				cardAsGood={cardAsGood}
				handleRemoveCardAsGood={handleRemoveCardAsGood}
				images={data.images}
				handleSetTags={handleSetTags}
			/>
			<FullSizeImageDialog
				open={openImageModal}
				setOpen={setOpenImageModal}
				imageIndexToShow={imageIndex}
				images={data.images}
			/>
		</>
	);
};

export default CardFront;
