import React, { useEffect, useState } from 'react';
import {
	Button,
	Card,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Typography,
} from '@mui/material';
import {
	Cancel,
	Check,
	CheckCircle,
	Close,
	CloseRounded,
	LaunchRounded,
	NewReleases,
	Share,
	VerifiedUser,
} from '@mui/icons-material/';
import { makeStyles } from '@mui/styles';
import { Column, Line } from '../../../styles';
import { usePO } from '../../../utils/POContext';
import { useTranslation } from 'react-i18next';
import FormDivider from '../../../components/FormDivider';
import {
	OfferToolTip,
	checkIsDate,
	convertDate,
	handlePlatformINCountry,
} from '../../../helpers';
import PlatformIcon from '../../../components/PlatformIcon';
import { getOfferByAuthor } from '../../../services/authors/offersByAuthorID';
import { useSnackbar } from 'notistack';
import BackDropLoading from '../../../components/BackdropLoading';
import OffersFromAuthorsTable from './OffersFromAuthorsTable';
import SelectClient from '../../../components/SelectClient';

interface Props {
	authorData: any;
	open: boolean;
	handleClose: (state) => void;
}

const AuthorDetailsModal: React.FC<Props> = ({
	authorData,
	open,
	handleClose,
}: Props) => {
	const { t } = useTranslation();
	const { selectedTheme, userData, selectedClient } = usePO();
	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = useState<boolean>(false);
	const [authorOffers, setAuthorOffers] = useState<any>([]);
	const [reloadTableData, setReloadTableData] = useState<number>(0);

	const useStyles = makeStyles(() => ({
		root: {
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap',
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: 8,
			backgroundColor:
				selectedTheme.id === 'dark' ? selectedTheme.gradient : '',
			width: '50%',
			height: 225,
			padding: '16px',
		},
	}));

	const classes = useStyles();

	const elasticQuery = `{"preference":"ReactiveListResult"}\n{"query":{"bool":{"must":[{"term":{"author.authorID": "${authorData.authorID}"}},{"match":{"client.clientID": "${selectedClient.id}"}}]}},"size": 10000}\n`;

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			const response: any = await getOfferByAuthor(elasticQuery);
			if (response && response.status < 300) {
				setAuthorOffers(response);
			} else {
				if (response && response.status >= 400) {
					const message = response.message;
					enqueueSnackbar(t(`${message}`), { variant: 'error' });
				}
			}
			setTimeout(() => {
				setLoading(false);
			}, 1000);
		};
		if (open) fetchData();
		// eslint-disable-next-line
	}, [open, reloadTableData, selectedClient.id]);

	const reFetchData = () => {
		setReloadTableData(reloadTableData + 1);
	};

	const handleCopyLinkToURL = () => {
		const { protocol, host } = window.location;
		const url = `${protocol}//${host}/authorID/${authorData.authorID}`;
		navigator.clipboard
			.writeText(url)
			.then(() => {
				enqueueSnackbar(
					t('Classificações.Link copiado para a área de transferência'),
					{ variant: 'success' }
				);
			})
			.catch((err) => {
				console.error('Error copying to clipboard', err);
			});
	};

	const RenderFooter = () => (
		<DialogActions
			data-testid="footerAuthorModal"
			style={{
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
				padding: 10,
				height: 60,
				background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
			}}
		>
			<Line style={{ justifyContent: 'space-between' }}>
				<Line style={{ width: 'auto', gap: 5 }}>
					<IconButton
						onClick={handleCopyLinkToURL}
						style={{ padding: 6, marginLeft: 3 }}
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<Share
							style={{
								fontSize: 20,
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.primaryDark,
							}}
						/>
					</IconButton>
					<IconButton
						href={authorData?.permalink}
						target="_blank"
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<LaunchRounded
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.primaryDark,
							}}
						/>
					</IconButton>
				</Line>
				<Line style={{ width: 'auto', gap: 5 }}>
					<Typography
						style={{
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
						}}
					>
						{t('Cliente')}:
					</Typography>
					<SelectClient width="200px" />
				</Line>
				<Button
					onClick={handleClose}
					size="small"
					variant="contained"
					style={{
						border: `1px solid ${
							selectedTheme.id === 'dark'
								? selectedTheme.footerLine
								: 'transparent'
						}`,
						color:
							selectedTheme.tone === 'dark'
								? selectedTheme.textColorHigh
								: selectedTheme.foreground,
						background: selectedTheme.primaryDark,
						width: 'auto',
					}}
				>
					{t('Autores.Fechar')}
				</Button>
			</Line>
		</DialogActions>
	);

	const renderBooleanValue = (value: boolean) =>
		value === true ? (
			<Check style={{ color: selectedTheme.success, fontSize: 14 }} />
		) : (
			<Close style={{ color: selectedTheme.error, fontSize: 14 }} />
		);

	const renderValueFormat = (infoType: string, value: any) => {
		if (value) {
			if (typeof value === 'boolean') {
				return renderBooleanValue(value);
			}
			if (
				infoType.toLowerCase().includes('valor') ||
				infoType.toLowerCase().includes('preço')
			) {
				return value.toLocaleString(userData.i18nID, {
					style: 'currency',
					currency: value,
					minimumFractionDigits: 2,
				});
			}
			if (checkIsDate(value)) {
				return convertDate(value, userData.i18nID, 'default');
			}
			return value;
		}
		return '-';
	};

	const handleConvertTooltipObject = (keyName: any, value: any) => {
		if (typeof value === 'boolean') {
			return value ? t('Autores.Sim') : t('Autores.Não');
		} else if (value) {
			return renderValueFormat(keyName, value);
		} else {
			return '-';
		}
	};

	const RenderAuthorDetailsCard = () => {
		return (
			<Card className={classes.root} style={{ alignItems: 'start' }}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
						height: '100%',
						gap: 5,
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					}}
				>
					<FormDivider
						name={`${t('Autores.Detalhes do autor')}`}
						margin="8px 0px"
						opacity={0.8}
						background={
							selectedTheme.id === 'dark' ? selectedTheme.foreground : '#fafafa'
						}
						color={
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : ''
						}
					/>
					<div
						style={{
							overflow: 'auto',
							gap: 5,
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						{Object.entries(authorData.authorData).map((el: any, index) => {
							const textTooltip = handleConvertTooltipObject(
								el[1]?.key_name,
								el[1]?.value
							);
							return (
								<Line
									key={index}
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										paddingRight: 16,
										gap: 10,
									}}
								>
									<OfferToolTip arrow title={t(`Autores.${el[1].key_name}`)}>
										<Typography
											style={{
												fontWeight: 'bold',
												color:
													selectedTheme.id === 'dark'
														? selectedTheme.textColorHigh
														: '',
											}}
										>
											{t(`Autores.${el[1].key_name}`)}:
										</Typography>
									</OfferToolTip>
									<OfferToolTip arrow title={textTooltip}>
										<Typography
											noWrap
											style={{
												textOverflow: 'ellipsis',
											}}
										>
											{typeof el[1].value === 'boolean'
												? renderBooleanValue(el[1].value)
												: renderValueFormat(el[1].key_name, el[1].value)}
										</Typography>
									</OfferToolTip>
								</Line>
							);
						})}
					</div>
				</div>
			</Card>
		);
	};

	const handleConvertLocationString = () => {
		if (authorData.cityName || authorData.stateName || authorData.countryName) {
			return `${authorData.cityName ? `${authorData.cityName}, ` : ''} ${
				authorData.stateName ? `${authorData.stateName} - ` : ''
			}${authorData.countryName || ''}`;
		} else return '-';
	};

	const RenderAuthorBasicInfoCard = () => (
		<Card className={classes.root}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					justifyContent: 'space-between',
					width: '100%',
					color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
				}}
			>
				<Column style={{ gap: 5 }}>
					<FormDivider
						name={`${t('Autores.Informações básicas')}`}
						margin="8px 0"
						opacity={0.8}
						background={
							selectedTheme.id === 'dark' ? selectedTheme.foreground : '#fafafa'
						}
						color={
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : ''
						}
					/>
					<Line style={{ justifyContent: 'space-between' }}>
						<Typography
							style={{
								fontWeight: 'bold',
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: '',
							}}
						>{`${t('Autores.Nome do perfil')}:`}</Typography>
						<Typography>{authorData.authorID}</Typography>
					</Line>
					<Line style={{ justifyContent: 'space-between' }}>
						<Typography
							style={{
								fontWeight: 'bold',
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: '',
							}}
						>{`${t('Autores.Nome do autor')}:`}</Typography>
						<Typography>{authorData.authorName}</Typography>
					</Line>
					<Line style={{ justifyContent: 'space-between' }}>
						<Typography
							style={{
								fontWeight: 'bold',
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: '',
							}}
						>{`${t('Autores.Localidade')}:`}</Typography>
						<Typography>{handleConvertLocationString()}</Typography>
					</Line>
					<Line style={{ justifyContent: 'space-between' }}>
						<Typography
							style={{
								fontWeight: 'bold',
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: '',
							}}
						>{`${t('Autores.Data de Inclusão')}:`}</Typography>
						<Typography>
							{convertDate(authorData.created, userData.i18nID, 'default')}
						</Typography>
					</Line>
				</Column>
				<Column style={{ gap: 5 }}>
					<FormDivider
						name={`${t('Autores.Plataforma')}`}
						margin="8px 0"
						opacity={0.8}
						background={
							selectedTheme.id === 'dark' ? selectedTheme.foreground : '#fafafa'
						}
						color={
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : ''
						}
					/>
					<Line style={{ justifyContent: 'space-between' }}>
						<div
							style={{
								gap: 12,
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
							}}
						>
							<PlatformIcon
								data={authorData.platformINcountryID}
								dataType="platform"
								size={34}
								cursor="pointer"
							/>
							<Typography
								style={{
									fontWeight: 'bold',
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorHigh
											: '',
								}}
							>
								{`${handlePlatformINCountry(
									authorData.platformINcountryID,
									userData.i18nID
								)}`}
							</Typography>
						</div>
						<div
							style={{
								gap: 8,
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
							}}
						>
							<IconButton
								size="small"
								href={authorData.permalink}
								target="_blank"
								style={{ borderRadius: 8, padding: 4 }}
								sx={{
									'&:hover': {
										backgroundColor:
											selectedTheme.id === 'dark' && selectedTheme.primaryLight,
									},
								}}
							>
								<Line style={{ gap: 5 }}>
									{authorData.ISactive ? (
										<CheckCircle
											style={{
												color: selectedTheme.success,
												fontSize: 16,
											}}
										/>
									) : (
										<Cancel
											style={{
												color: selectedTheme.error,
												fontSize: 16,
											}}
										/>
									)}

									<Typography
										style={{
											fontWeight: 'bold',
											textDecoration: 'underline',
											color:
												selectedTheme.id === 'dark'
													? selectedTheme.textColorHigh
													: '#000',
										}}
									>
										{authorData.ISactive
											? t('Autores.Perfil Ativo')
											: t('Autores.Perfil Desativado')}
									</Typography>
								</Line>
							</IconButton>
							<Typography
								style={{
									fontWeight: 'bold',
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorHigh
											: '#000',
								}}
							>
								-
							</Typography>
							<Line style={{ gap: 5 }}>
								{authorData.official?.value ? (
									<VerifiedUser
										style={{
											color: selectedTheme.success,
											fontSize: 16,
										}}
									/>
								) : (
									<NewReleases
										style={{
											color: selectedTheme.error,
											fontSize: 16,
										}}
									/>
								)}
								<Typography
									style={{
										fontWeight: 'bold',
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorHigh
												: '#000',
									}}
								>
									{authorData.official?.value
										? t('Autores.Oficial')
										: t('Autores.Não Oficial')}
								</Typography>
							</Line>
						</div>
					</Line>
				</Column>
			</div>
		</Card>
	);

	const RenderDialogContent = () => (
		<DialogContent
			style={{
				padding: 20,
				background:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay8dp
						: selectedTheme.lightBackground,
				color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
			}}
		>
			<div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
				<Line style={{ gap: '20px' }}>
					<RenderAuthorBasicInfoCard />
					<RenderAuthorDetailsCard />
				</Line>
				<OffersFromAuthorsTable
					data={authorOffers.data}
					reFetchData={reFetchData}
				/>
			</div>
		</DialogContent>
	);

	const RenderHeader = () => (
		<DialogTitle
			style={{
				height: 60,
				display: 'flex',
				background:
					selectedTheme.id === 'dark'
						? selectedTheme.tableHead
						: selectedTheme.gradient,
				color:
					selectedTheme.id === 'dark'
						? selectedTheme.textColorHigh
						: selectedTheme.primary,
			}}
			id="max-width-dialog-title"
		>
			<Line
				style={{
					justifyContent: 'space-between',
				}}
			>
				<Typography
					noWrap
					style={{
						color:
							selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
						fontSize: 20,
						fontWeight: 'bold',
						maxWidth: '90%',
					}}
				>
					{`${t('Autores.Dados do autor/vendedor')}: ${
						authorData.authorName || authorData.authorData.nickname?.value
					} `}
				</Typography>
				<IconButton
					onClick={handleClose}
					style={{ marginRight: '-16px' }}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<CloseRounded
						style={{
							color:
								selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
						}}
					/>
				</IconButton>
			</Line>
		</DialogTitle>
	);

	const renderModalContent = () =>
		authorOffers.length < 0 || authorData.length < 0 || loading ? (
			<BackDropLoading data-testid="loadingModal" smallBackdrop />
		) : (
			<Dialog
				open={open}
				onClose={handleClose}
				aria-label="Author-modal"
				fullWidth
				maxWidth="lg"
				scroll="body"
			>
				<RenderHeader />
				{!loading ? <RenderDialogContent /> : null}
				<RenderFooter />
			</Dialog>
		);

	return <>{authorData && renderModalContent()}</>;
};

export default AuthorDetailsModal;
