import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	classifications: {
		defaultFilters: {
			nameSearch: '',
			titleSearch: '',
			selectedUser: '',
			denouncements: '',
			sellerSearch: '',
			anatelSearch: '',
			observationSearch: '',
			saveTags: [],
			tagsID: [],
			orLogic: true,
			priceSelect: [0, 9999.99999],
			followersSelect: [0, 9999.99999],
			itemsSoldSelect: [0, 9999.99999],
			sourceSearch: [],
			categorySearch: [],
			platformSearch: [],
			denouncerSearch: [],
			categoriesSearch: [],
			countrySearch: [],
			citySearch: [],
			stateSearch: [],
			viewDenouncements: '',
			viewDenouncementItems: true,
			viewAllowedItems: false,
			viewTagIDItems: true,
			dateRangeSeek: { start: '', end: '' },
		},
	},
	notifications: {
		defaultFilters: {
			nameSearch: '',
			titleSearch: '',
			permalinkSearch: '',
			offerIDSearch: '',
			sellerSearch: '',
			saveTags: [],
			tagsID: [],
			orLogic: true,
			sourceSearch: [],
			platformSearch: [],
			denouncerSearch: [],
			classficationTypesID: [],
			classficationType: [],
			countrySearch: [],
			citySearch: [],
			stateSearch: [],
			dateRangeSearch: { start: '', end: '' },
			categoriesSearch: [],
		},
	},
	authors: {
		defaultFilters: {
			nameSearch: '',
			idAuthorSearch: [],
			saveTags: [],
			tagsID: [],
			orLogic: true,
			platformSearch: [],
			countrySearch: [],
			citySearch: [],
			stateSearch: [],
		},
	},
	denouncements: {
		defaultFilters: {
			titleToSearch: '',
			urlToSearch: '',
			selectedStartDate: null,
			selectedFinalDate: null,
			denounceStatusIDs: [],
			selectedPlatform: null,
			selectedCountry: null,
			selectedSource: null,
			sourceIDToSearch: null,
			platformINcountryIDToSearch: null,
			denouncementTypeIDToSearch: null,
			selectedUser: '',
			selectedTags: [],
			denouncementSources: [],
			selectedFieldToOrderBy: null,
			selectedOrdination: null,
		},
	},
};

export const filtersSlice = createSlice({
	name: 'filters',
	initialState,
	reducers: {
		updateClassificationsFilters(state, { payload }) {
			const { filterName, filterData } = payload;
			state.classifications[filterName] = filterData;
		},
		updateNotificationsFilters(state, { payload }) {
			const { filterName, filterData } = payload;
			state.notifications[filterName] = filterData;
		},
		updateAuthorsFilters(state, { payload }) {
			const { filterName, filterData } = payload;
			state.authors[filterName] = filterData;
		},
		updateDenouncementsFilters(state, { payload }) {
			const { filterName, filterData } = payload;
			state.denouncements[filterName] = filterData;
		},

		resetFilters(state) {
			state.classifications = initialState.classifications;
			state.notifications = initialState.notifications;
			state.authors = initialState.authors;
		},
		removeSpecificFilter(state, { payload }) {
			const { filterType, filterName } = payload;
			if (state[filterType] && state[filterType][filterName]) {
				if (filterName !== 'defaultFilters') {
					delete state[filterType][filterName];
				}
			}
		},
		updateDenouncementsField: (state, action) => {
			const { fieldName, value, filterName } = action.payload;
			if (state.denouncements && state.denouncements[filterName]) {
				state.denouncements[filterName][fieldName] = value;
			}
		},
		resetDenouncementsFilters(state) {
			if (state.denouncements) {
				state.denouncements.defaultFilters =
					initialState.denouncements.defaultFilters;
			}
		},
	},
});

export const {
	updateClassificationsFilters,
	updateNotificationsFilters,
	updateAuthorsFilters,
	updateDenouncementsFilters,
	resetFilters,
	removeSpecificFilter,
	resetDenouncementsFilters,
	updateDenouncementsField,
} = filtersSlice.actions;

export default filtersSlice.reducer;
