import {
	Cached,
	FirstPage,
	KeyboardArrowLeft,
	KeyboardArrowRight,
	LastPage,
} from '@mui/icons-material';
import { Zoom, IconButton, Box, Chip, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import OfferTable from '../../../components/OfferTable';
import { OfferToolTip } from '../../../helpers';
import { usePO } from '../../../utils/POContext';
import LogsChart from './LogsCharts';
import MonitoringToggleDialog from './MonitoringToggleDialog';
import { enqueueSnackbar } from 'notistack';
import { getDomainsFilters } from '../../../services/domains';
import { Line } from '../../../styles';
import SelectActiveClient from '../../../components/SelectClient';
import { TableData, Item } from '../../../interfaces/Domains';
import { closeAllPanels } from '../../../redux/slices/expansibleTableSlice';
import { SearchParameter } from '../../../interfaces';
import { useDispatch } from 'react-redux';
import TableSearch from '../../../components/TableSearch';
import { useHistory, useLocation } from 'react-router-dom';

const Monitoring: React.FC = () => {
	const { selectedTheme, selectedClient } = usePO();
	const dispatch = useDispatch();

	const history = useHistory();

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const searchID = searchParams.get('searchID');

	const [tableData, setTableData] = useState<TableData[]>([]);

	const [refreshTable, setRefreshTable] = useState(0);
	const [loading, setLoading] = useState(false);

	const [openToggleDialog, setOpenToggleDialog] = useState(false);

	const [receivedDataSwitch, setReceivedDataSwitch] = useState<Item | null>(
		null
	);
	const [tableLength, setTableLength] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(
		window.innerHeight > 750 ? 10 : Math.round(window.innerHeight / 100) + 1
	);
	const [currentPage, setCurrentPage] = useState(0);
	const [displayCurrentPage, setDisplayCurrentPage] = useState(0);
	const [currentPageChange, setCurrentPageChange] = useState(0);
	const [searchFieldsArray, setSearchFieldsArray] = useState<SearchParameter[]>(
		[]
	);
	const [searchText, setSearchText] = useState('');

	const handleRefreshTable = async () => {
		setRefreshTable(refreshTable + 1);
		history.push('/monitoramento');
	};

	const handleOpenToggleCategoriesDialog = () => {
		setOpenToggleDialog(true);
	};

	useEffect(() => {
		if (selectedClient?.id !== undefined) {
			setLoading(true);
			fetchData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		selectedClient,
		currentPageChange,
		rowsPerPage,
		refreshTable,
		searchText,
		searchFieldsArray,
		searchID,
	]);

	const renderFilter = () => (
		<Line>
			<Typography
				style={{
					margin: '0 5px 0 20px',
					color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
				}}
			>
				{t('Buscas.Tabs.Monitoramento.Cliente')}:
			</Typography>
			<SelectActiveClient width="200px" />
		</Line>
	);

	const renderStatus = (status) => {
		const tooltipMessage =
			status === 'Online'
				? t('Buscas.Tabs.Monitoramento.Domínio ativo no momento')
				: t('Buscas.Tabs.Monitoramento.Domínio desativado no momento');

		return (
			<OfferToolTip title={tooltipMessage} placement="bottom" arrow>
				<Chip
					label={status}
					variant="outlined"
					icon={
						<Box
							sx={{
								width: 10,
								height: 10,
								borderRadius: '50%',
								backgroundColor: status === 'Online' ? '#5DED55' : '#ED5555',
								border: `1px solid ${status === 'Online' ? '#24891B' : '#891B1B'}`,
								marginLeft: '8px',
							}}
						/>
					}
					sx={{
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
						fontWeight: 'normal',
						borderRadius: '16px',
						padding: '4px 8px',
						borderColor: selectedTheme.id === 'dark' ? '#585858' : '',
					}}
				/>
			</OfferToolTip>
		);
	};

	const renderReloadTableData = () => (
		<OfferToolTip
			title={`${t('Buscas.Tabs.Monitoramento.Atualizar dados da tabela')}`}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				onClick={handleRefreshTable}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<Cached
					sx={{
						animation: loading ? '$spin 2s linear infinite' : 'normal',
						'@keyframes spin': loading
							? {
									'0%': {
										transform: 'rotate(360deg)',
									},
									'100%': {
										transform: 'rotate(0deg)',
									},
								}
							: null,
						zIndex: !loading ? 2 : 'auto',
					}}
					style={{
						fontSize: 22,
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);

	const rowsPerPageOptions = [
		window.innerHeight > 750 ? 10 : Math.round(window.innerHeight / 100) + 1,
		50,
		100,
	];

	const handleChangeRowsPerPage = (event) => {
		if (currentPage > 0) {
			setCurrentPage(0);
			setDisplayCurrentPage(0);
			setLoading(true);
			setRowsPerPage(event.target.value);
		}
		if (currentPage === 0) {
			setRowsPerPage(event.target.value);
		}
	};

	const handleChangePage = (event, newPage: React.SetStateAction<number>) => {
		dispatch(closeAllPanels());
		setLoading(true);
		setCurrentPage(newPage);
		setCurrentPageChange(currentPageChange + 1);
	};

	const handleKeyArrowStyle = (disableVerification) => {
		if (disableVerification) {
			return selectedTheme.id === 'dark' ? selectedTheme.textColorDisable : '';
		} else {
			return selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '';
		}
	};

	function tablePaginationActions() {
		const handleFirstPageButtonClick = (
			event: React.MouseEvent<HTMLButtonElement>
		) => {
			handleChangePage(event, 0);
		};

		const handleBackButtonClick = (
			event: React.MouseEvent<HTMLButtonElement>
		) => {
			handleChangePage(event, currentPage - 1);
		};

		const handleNextButtonClick = (
			event: React.MouseEvent<HTMLButtonElement>
		) => {
			handleChangePage(event, currentPage + 1);
		};

		const handleLastPageButtonClick = (
			event: React.MouseEvent<HTMLButtonElement>
		) => {
			handleChangePage(
				event,
				Math.max(0, Math.ceil(tableLength / rowsPerPage) - 1)
			);
		};
		return (
			<Box
				style={{
					flexShrink: 0,
					marginLeft: 30,
				}}
			>
				<Line>
					<IconButton
						onClick={handleFirstPageButtonClick}
						disabled={currentPage === 0}
						aria-label="first page"
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<FirstPage
							style={{ color: handleKeyArrowStyle(currentPage === 0) }}
						/>
					</IconButton>
					<IconButton
						onClick={handleBackButtonClick}
						disabled={currentPage === 0}
						aria-label="previous page"
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<KeyboardArrowLeft
							style={{ color: handleKeyArrowStyle(currentPage === 0) }}
						/>
					</IconButton>
					<IconButton
						onClick={handleNextButtonClick}
						disabled={currentPage >= Math.ceil(tableLength / rowsPerPage) - 1}
						aria-label="next page"
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<KeyboardArrowRight
							style={{
								color: handleKeyArrowStyle(
									currentPage >= Math.ceil(tableLength / rowsPerPage) - 1
								),
							}}
						/>
					</IconButton>
					<IconButton
						onClick={handleLastPageButtonClick}
						disabled={currentPage >= Math.ceil(tableLength / rowsPerPage) - 1}
						aria-label="last page"
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<LastPage
							style={{
								color: handleKeyArrowStyle(
									currentPage >= Math.ceil(tableLength / rowsPerPage) - 1
								),
							}}
						/>
					</IconButton>
				</Line>
			</Box>
		);
	}

	const switchesToolTip = {
		true: t('Buscas.Tabs.Monitoramento.Monitoramento ativado'),
		false: t('Buscas.Tabs.Monitoramento.Monitoramento desativado'),
	};

	const colsData = [
		{
			columnTitle: t('Buscas.Tabs.Monitoramento.ID da busca'),
			fieldName: 'searchID',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 30,
		},
		{
			columnTitle: t('Buscas.Tabs.Monitoramento.Endereço'),
			fieldName: 'url',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 190,
		},
		{
			columnTitle: t('Buscas.Tabs.Monitoramento.Registros'),
			fieldName: 'logsDisplay',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 100,
			dataType: 'reactObject',
		},
		{
			columnTitle: t('Buscas.Tabs.Monitoramento.Status'),
			fieldName: 'ISonlineDisplay',
			translateColumnData: true,
			alignTitle: 'center',
			cellWidth: 80,
			dataType: 'reactObject',
		},
		{
			columnTitle: t('Buscas.Tabs.Monitoramento.Data'),
			fieldName: 'created',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 90,
			dataType: 'date',
		},
	];

	const fetchData = async () => {
		setLoading(true);
		const searchPayload = {
			clientID: selectedClient.id,
			itemsPerPage: rowsPerPage,
			page: currentPage + 1,
			orderField: 'id',
			ordination: 'ASC',
			searchID: searchID,
			url: '',
		};
		if (searchFieldsArray.length > 0) {
			searchFieldsArray.forEach((fieldObj) => {
				if (fieldObj.value !== '') {
					searchPayload[fieldObj.field] = fieldObj.value;
				}
			});
		}
		const response = await getDomainsFilters(searchPayload);
		if (response?.success === true) {
			buildTableData(response.data.hits);
			setTableLength(response.data.totalHits);
			setDisplayCurrentPage(currentPage);
		} else {
			enqueueSnackbar(t(`Buscas.Tabs.Monitoramento.${response?.message}`), {
				variant: 'error',
			});
		}
	};

	const buildTableData = async (item: any) => {
		if (item) {
			setTableData([]);
			const tempTableData: TableData[] = [];
			const itemsArray = Array.isArray(item) ? item : Object.values(item);
			await itemsArray.map((item: any) => {
				const hasLogs = item.logs && Object.keys(item.logs).length > 0;
				const formattedData: TableData = {
					id: item.id,
					searchID: item.searchID,
					url: item.url,
					clientID: item.clientID,
					actions: item.actions,
					eventDate: item.eventDate,
					logs: item.logs || {},
					logsDisplay: hasLogs ? <LogsChart logs={item.logs} /> : '-',
					status: item.ISonline,
					ISonlineDisplay: renderStatus(item.ISonline ? 'Online' : 'Offline'),
					created: item.created,
					ISactive: item.ISactive || false,
				};
				tempTableData.push(formattedData);
				return null;
			});
			setTableData(tempTableData);
			setLoading(false);
		}
	};

	const fieldToSearch = [
		{ field: 'url', name: t('Buscas.Tabs.Monitoramento.Endereço') },
	];

	return (
		<>
			<OfferTable
				tableData={tableData}
				colsData={colsData}
				customCurrentPage={displayCurrentPage}
				customRowsPerPage={rowsPerPage}
				customRowsPerPageOptions={rowsPerPageOptions}
				customTableLength={tableLength}
				customTablePagination={tablePaginationActions}
				handleChangeCustomRowsPerPage={handleChangeRowsPerPage}
				tableActions
				tableFooter
				customRightFooterComponent={renderFilter()}
				customLeftFooterComponent={renderReloadTableData()}
				loading={loading}
				customSearch={
					<TableSearch
						setSearchText={setSearchText}
						setSearchFieldsArray={setSearchFieldsArray}
						searchFieldsArray={searchFieldsArray}
						customTooltip={t(
							'Buscas.Tabs.Monitoramento.Buscar por endereço na tabela'
						)}
						fields={fieldToSearch}
					/>
				}
				customNoDataMessage={
					// eslint-disable-next-line no-nested-ternary
					selectedClient
						? t(
								'Buscas.Tabs.Monitoramento.Sem monitoramentos para este cliente'
							)
						: t('Buscas.Tabs.Monitoramento.Selecione um cliente')
				}
				switches
				switchesToolTip={switchesToolTip}
				dataFromSwitch={setReceivedDataSwitch}
				onSwitchChange={handleOpenToggleCategoriesDialog}
			/>

			<MonitoringToggleDialog
				open={openToggleDialog}
				setOpen={setOpenToggleDialog}
				data={receivedDataSwitch}
				onConfirm={fetchData}
			/>
		</>
	);
};

export default Monitoring;
